import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect, useRef } from "react";
import { useMedia } from "react-use";
import { LoadedSessionContext } from "supertokens-auth-react/lib/build/recipe/session/types";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import Container from "./Container";
import Head from "./Head";
import Header from "./Header";
import MobileHeader from "./MobileHeader";
import Sidebar from "./Sidebar";
import TopNotificationBeta from "./TopNotificationBeta";

const tawkPropertyId = process.env.NEXT_PUBLIC_TAWK_PROPERTY_ID;
const tawkWidgetId = process.env.NEXT_PUBLIC_TAWK_WIDGET_ID;

interface MasterLayoutProps {
  children: React.ReactNode;
}

const MasterLayout: React.FC<MasterLayoutProps> = ({ children }) => {
  const posthog = usePostHog();
  const session = useSessionContext();
  const tawkMessengerRef = useRef();
  const isMobile = useMedia("(max-width: 768px)"); // same as "md" in Chakra UI

  const setUserInTawk = useCallback(
    (data: { userId: string; email: string; userName: string }) => {
      // @ts-expect-error lib doesn't have types
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      tawkMessengerRef.current?.visitor(
        {
          name: data.userName,
          email: data.email,
          // hash: tawkHash,
        },
        function (error) {
          if (error) {
            console.log(error);
          }
        }
      );
      // @ts-expect-error lib doesn't have types
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      tawkMessengerRef.current?.setAttributes(
        {
          name: data.userName,
          email: data.email,
          id: data.userId,
          // hash: tawkHash,
        },
        function (error) {
          if (error) {
            console.log(error);
          }
        }
      );
    },
    []
  );

  const setUserInPosthog = useCallback(
    (data: { userId: string; email: string }) => {
      console.log("called setUserInPosthog", data);
      posthog.identify(data.userId);
      posthog.people.set({ email: data.email }, undefined, () =>
        console.log("user ok")
      );
    },
    [posthog]
  );

  useEffect(() => {
    if (session.loading === false && session.userId) {
      setUserInPosthog({
        userId: session.userId,
        email: session.accessTokenPayload?.email,
      });
      setUserInTawk({
        userId: session.userId,
        email: session.accessTokenPayload?.email,
        userName: session.accessTokenPayload?.firstName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    session.loading,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (session as LoadedSessionContext).userId,
    setUserInPosthog,
  ]);

  // Because we don't kown what loads first (next auth session, or tawk messenger)
  const onLoadTawk = useCallback(() => {
    if (session.loading === false && session.userId) {
      setUserInTawk({
        userId: session.userId,
        email: session.accessTokenPayload?.email,
        userName: session.accessTokenPayload?.firstName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    session.loading,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (session as LoadedSessionContext).userId,
    setUserInTawk,
  ]);

  return (
    <>
      <Head title="Wonder Money" description="Wonder Money" />
      {!isMobile && (
        <>
          <TopNotificationBeta />
          <Sidebar />
          <Header />
        </>
      )}
      {isMobile && <MobileHeader />}
      <Container isMobile={isMobile}>
        {children}
        <TawkMessengerReact
          propertyId={tawkPropertyId}
          widgetId={tawkWidgetId}
          useRef={tawkMessengerRef}
          onLoad={onLoadTawk}
        />
      </Container>
    </>
  );
};

export default MasterLayout;
