import { Badge, Button, Flex, Icon, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useMemo, useState } from "react";
import { IconType } from "react-icons";
import { BsFillHouseFill, BsQuestionCircle } from "react-icons/bs";
import {
  FaBookOpen,
  FaBriefcase,
  FaChartLine,
  FaChartPie,
  FaChevronRight,
  FaCog,
  FaTable,
  FaUser,
} from "react-icons/fa";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import AdminModal from "../AdminModal";

const Logo = styled.a`
  margin: 1rem 0;
  display: block;
  text-align: center;
  font-size: 1.5rem;
  color: #a4a6b3;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
`;

interface NavListProps {
  isOpen: boolean;
  numChildren: number;
}

const List = styled.ul`
  list-style: none;
`;

const NavList = styled(List)<NavListProps>`
  height: ${({ isOpen, numChildren }) =>
    isOpen ? `${numChildren * 44}px` : 0};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  /* This refers to sub-list items (an *li* in a nested list) which don't have icons, so we need to push them to the right */
  padding-left: 24px !important;
  /* background-color: rgb(21, 20, 41); */
  background-color: #151e2e;
  overflow: hidden;
  transition: height 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const AnchorItem = styled.a`
  text-decoration: none;
  letter-spacing: 0.3px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

interface HeaderLogoProps {
  isMobile?: boolean;
}

export const HeaderLogo: React.FC<HeaderLogoProps> = ({ isMobile = false }) => {
  return (
    <NextLink href="/" legacyBehavior passHref>
      <Logo>
        <Badge
          colorScheme="brand"
          variant="subtle"
          position="absolute"
          top={0}
          right={isMobile ? 0 : 4}
          size="sm"
        >
          Beta
        </Badge>
        <Image
          priority
          src="/logo.png"
          alt="WonderMoney"
          width="200"
          height="0"
          sizes="100vw"
          style={{ maxWidth: "200px", width: "100%", height: "auto" }}
        />
      </Logo>
    </NextLink>
  );
};

interface NavItemContentProps {
  isActive: boolean;
  showSoonBadge?: boolean;
  isSecondary?: boolean;
}

const NavItemContent = styled.div<NavItemContentProps>`
  display: flex;
  align-items: center;
  color: ${({ isActive, isSecondary, theme }) =>
    isActive
      ? isSecondary
        ? theme.colors.gray[300]
        : theme.colors.gray[100]
      : isSecondary
      ? theme.colors.gray[500]
      : theme.colors.gray[400]};
  height: ${({ isSecondary }) => (isSecondary ? "32px" : "40px")};
  padding: ${({ isSecondary }) => (isSecondary ? "0 16px" : "0 24px")};
  margin: ${({ isSecondary }) => (isSecondary ? "2px 0" : "4px 0")};
  white-space: nowrap;
  cursor: ${({ showSoonBadge }) =>
    !showSoonBadge ? "pointer" : "not-allowed"};
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  pointer-events: ${({ showSoonBadge }) => (!showSoonBadge ? "auto" : "none")};
  :hover {
    color: ${({ showSoonBadge, theme }) =>
      !showSoonBadge ? theme.colors.gray[100] : undefined};
  }
  > svg {
    font-size: 0.8rem;
    &:first-of-type {
      margin-right: ${({ isSecondary }) => (isSecondary ? "4px" : "8px")};
    }
    &:last-of-type {
      margin-left: auto;
    }
  }
`;

interface NavItemProps {
  leadingIcon?: IconType;
  trailingIcon?: IconType;
  text: string;
  href: string;
  showSoonBadge?: boolean;
  isSecondary?: boolean;
  isExternal?: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
  leadingIcon,
  href,
  text,
  trailingIcon,
  showSoonBadge = false,
  isSecondary = false,
  isExternal = false,
}) => {
  const posthog = usePostHog();
  const router = useRouter();
  const isActive = useMemo(
    () => router.pathname === href,
    [router.pathname, href]
  );
  const onNavItemClick = () => {
    if (!isActive) {
      posthog.capture("sidebar_nav_item_click", { href });
    }
  };
  return (
    <li>
      <NavItemContent
        isActive={isActive}
        showSoonBadge={showSoonBadge}
        isSecondary={isSecondary}
      >
        {leadingIcon && <Icon as={leadingIcon} />}
        <NextLink href={href} legacyBehavior passHref>
          <AnchorItem
            onClick={onNavItemClick}
            target={isExternal ? "_blank" : ""}
          >
            <Text as="span">{text}</Text>
            {showSoonBadge && (
              <Badge colorScheme="gray" variant="subtle">
                Soon
              </Badge>
            )}
          </AnchorItem>
        </NextLink>
        {trailingIcon && <Icon as={trailingIcon} />}
      </NavItemContent>
    </li>
  );
};

interface NavItemWithChildrenProps {
  leadingIcon: IconType;
  trailingIcon?: IconType;
  text: string;
  parentRouteMatch: string;
  showSoonBadge?: boolean;
  children?: React.ReactNode;
}

const NavItemWithChildren: React.FC<NavItemWithChildrenProps> = ({
  leadingIcon,
  text,
  parentRouteMatch,
  children,
  showSoonBadge,
}) => {
  const router = useRouter();
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    setIsActive(router.pathname.startsWith(parentRouteMatch));
  }, [router.pathname, parentRouteMatch]);
  const toggleSubList = () => {
    setIsActive((prev) => !prev);
  };
  return (
    <li>
      <NavItemContent isActive={isActive} onClick={toggleSubList}>
        <Icon as={leadingIcon} />
        <Text as="span">{text}</Text>
        {showSoonBadge && (
          <Badge colorScheme="gray" variant="subtle">
            Soon
          </Badge>
        )}
        <Icon
          as={FaChevronRight}
          transition="transform 0.3s"
          transform={isActive ? "rotate(90deg)" : ""}
        />
      </NavItemContent>
      <NavList isOpen={isActive} numChildren={React.Children.count(children)}>
        {children}
      </NavList>
    </li>
  );
};

const Navigation: React.FC = () => {
  const { t } = useTranslation("navigation");

  return (
    <nav>
      <List>
        <NavItem leadingIcon={BsFillHouseFill} href="/" text={t("dashboard")} />
        <NavItemWithChildren
          leadingIcon={FaTable}
          text={t("accounts")}
          parentRouteMatch="/accounts"
        >
          <NavItem href="/accounts" text={t("overview")} />
          <NavItem href="/accounts/transactions" text={t("transactions")} />
        </NavItemWithChildren>
        <NavItemWithChildren
          leadingIcon={FaChartPie}
          text={t("budgets")}
          parentRouteMatch="/budgets"
        >
          <NavItem href="/budgets/overview" text={t("overview")} />
        </NavItemWithChildren>
        <NavItemWithChildren
          leadingIcon={FaBriefcase}
          text={t("portfolio")}
          parentRouteMatch="/portfolio"
        >
          <NavItem href="/portfolio" text={t("overview")} />
          <NavItem href="/portfolio/transactions" text={t("transactions")} />
          <NavItem href="/portfolio/holdings" text={t("holdings")} />
        </NavItemWithChildren>
        <NavItemWithChildren
          leadingIcon={FaChartLine}
          text={t("financial-markets")}
          parentRouteMatch="/financial-markets"
        >
          <NavItem href="/financial-markets/explorer" text={t("explorer")} />
          {/* <NavItem
              href="/financial-markets/watchlist"
              text={t("watchlist")}
            /> */}
          <NavItem href="/financial-markets/screener" text={t("screener")} />
          <NavItem href="/financial-markets/news" text={t("news")} />
        </NavItemWithChildren>

        <NavItem leadingIcon={FaBookOpen} href="/reports" text={t("reports")} />

        {/* <NavItem
            leadingIcon={FaMoneyBillWaveAlt}
            href="/billing"
            text={t("navigation:billing")}
          /> */}
        {/* <NavItem
            leadingIcon={FaUserPlus}
            href="/referral"
            text={t("referral")}
          /> */}
      </List>
    </nav>
  );
};

const BottomNav: React.FC = () => {
  const { t } = useTranslation("navigation");
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const session = useSessionContext();
  const userEmail =
    session.loading === false && session.accessTokenPayload?.email;
  const isAdmin = userEmail === "admin@wonder.money";
  const isDemo = userEmail === "demo@wonder.money";

  const onAdminButtonClick = () => {
    setIsAdminModalOpen(true);
  };

  return (
    <>
      <Flex mt="auto" flexDirection="column">
        {isDemo && (
          <Text
            width="80%"
            color="slate.900"
            fontWeight="semibold"
            fontSize="sm"
            letterSpacing="wide"
            bgColor="slate.400"
            borderRadius="sm"
            py={1}
            px={2}
            textAlign="center"
            m="0 auto 1rem auto"
          >
            You are in demo mode
          </Text>
        )}
        {isAdmin && (
          <Button
            size="md"
            width="100px"
            onClick={onAdminButtonClick}
            margin="32px auto"
          >
            Admin
          </Button>
        )}
        <List>
          <NavItem
            leadingIcon={BsQuestionCircle}
            href="https://faq.wonder.money"
            text={t("help")}
            isSecondary
            isExternal
          />
          <NavItem
            leadingIcon={FaCog}
            href="/settings"
            text={t("settings")}
            isSecondary
          />
          <NavItem
            leadingIcon={FaUser}
            href="/myaccount"
            text={t("my-account")}
            isSecondary
          />
        </List>
        <Text
          fontSize="xs"
          wordBreak="break-all"
          px="4"
          color="gray.500"
          textAlign="center"
          my="2"
        >
          Version: b164e9d71d80f8fe91c9547a10bf676ec4c25dcb
        </Text>
      </Flex>
      <AdminModal
        isOpen={isAdminModalOpen}
        closeModal={() => setIsAdminModalOpen(false)}
      />
    </>
  );
};

const Sidebar: React.FC = () => {
  return (
    <Flex
      flexDirection="column"
      width="250px"
      height="100%"
      bgColor="slate.900"
      position="fixed"
      top="0"
      left="0"
      userSelect="none"
    >
      <HeaderLogo />
      <Navigation />
      <BottomNav />
    </Flex>
  );
};

export default Sidebar;
