import { CloseIcon } from "@chakra-ui/icons";
import { Flex, Heading, IconButton, useColorModeValue } from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import ReactModal from "react-modal";

// We are not using the default styles (relative + absolute positioning w/ transform) beucase of the
// Chrome bug related to blurry text when using transform
const defaultStyles: ReactModal.Styles = {
  content: {
    // position: "absolute",
    position: "static",
    backfaceVisibility: "hidden",
    // top: "50%",
    // left: "50%",
    // right: "auto",
    // bottom: "auto",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
    padding: "1rem",
    minWidth: "600px",
    maxWidth: "800px",
    borderRadius: "5px",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  },
  overlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
    backdropFilter: "blur(1px)",
  },
};

ReactModal.setAppElement("#__next");

export interface ModalProps {
  title: string;
  contentLabel: string;
  isOpen: boolean;
  closeModal?: () => void;
  children: React.ReactNode;
  opacity?: number;
  customStyles?: ReactModal.Styles;
  footer?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  title,
  isOpen,
  closeModal,
  contentLabel,
  children,
  customStyles,
  footer,
}) => {
  const theme = useTheme();
  const bgColor = useColorModeValue(
    theme.colors.gray[50],
    theme.colors.gray[700]
  );
  const opacity = useColorModeValue(0.5, 0.3);
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      // onAfterClose={aftercloseModal}
      style={{
        content: {
          backgroundColor: bgColor,
          borderColor: bgColor,
          ...defaultStyles.content,
          ...customStyles?.content,
        },
        overlay: {
          ...defaultStyles.overlay,
          backgroundColor: `rgba(0, 0, 0, ${opacity}`,
          ...customStyles?.overlay,
        },
      }}
      contentLabel={contentLabel}
    >
      <Flex flexDirection="column" gap={4}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          borderBottomWidth="1px"
          borderColor="slate.300"
          borderBottomStyle="solid"
          pb={2}
        >
          <Heading as="h2" size="lg">
            {title}
          </Heading>
          <IconButton
            onClick={closeModal}
            size="sm"
            bgColor="slate.200"
            _hover={{
              bgColor: "slate.300",
            }}
            aria-label="Close modal"
            icon={<CloseIcon boxSize={3} color="slate.600" />}
          />
        </Flex>
        <Flex>{children}</Flex>
        {footer && (
          <Flex justifyContent="flex-end" gap={2}>
            {footer}
          </Flex>
        )}
      </Flex>
    </ReactModal>
  );
};

export default Modal;
