import { ChakraProvider, createLocalStorageManager } from "@chakra-ui/react";
import { ThemeProvider } from "@emotion/react";
import "@fontsource/bitter/400.css";
import "@fontsource/bitter/600.css";
import "@fontsource/bitter/700.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import countries from "i18n-iso-countries";
import "intro.js/introjs.css";
import { appWithTranslation, i18n } from "next-i18next";
import NextProgress from "next-progress";
import { AppProps } from "next/app";
import Script from "next/script";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect, useState } from "react";
import { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SuperTokensReact, {
  redirectToAuth,
  SuperTokensWrapper,
} from "supertokens-auth-react";
import SuperTokensSession, {
  SessionAuth,
} from "supertokens-auth-react/recipe/session";
import Layout from "../components/layout/Layout";
import "../components/Onboarding.css";
import "../components/stripe/stripe.css";
import "../components/ui/DatePicker.css";
import "../components/ui/Modal.css";
import * as SuperTokensConfig from "../lib/supertokens/frontendConfig";
import "../scripts/wdyr";
import { extendedTheme } from "../theme";

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-var-requires
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const manager = createLocalStorageManager("wm-color-manager");

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: "https://eu.posthog.com",
    debug: process.env.NODE_ENV === "development",
    // Disable in development
    // loaded: (posthog) => {
    //   if (process.env.NODE_ENV === "development") posthog.opt_out_capturing();
    // },
  });
}

if (typeof window !== "undefined") {
  SuperTokensReact.init(SuperTokensConfig.frontendConfig());
}
i18n?.on("languageChanged", (lng) => {
  console.log("languageChanged", lng);
  SuperTokensReact.changeLanguage(lng);
});
i18n?.on("loaded", () => SuperTokensReact.loadTranslation({}));

const MyApp = ({
  Component,
  pageProps: { ...pageProps },
  router,
}: AppProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 2,
            // retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  useEffect(() => {
    async function doRefresh() {
      if (pageProps.fromSupertokens === "needs-refresh") {
        if (await SuperTokensSession.attemptRefreshingSession()) {
          location.reload();
        } else {
          // user has been logged out
          redirectToAuth();
        }
      }
    }
    doRefresh();
  }, [pageProps.fromSupertokens]);

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  let navigatorLanguage = "en-US";
  if (typeof window !== "undefined") {
    navigatorLanguage = window.navigator.language;
  }
  useEffect(() => {
    const importLocaleFile = async () => {
      // This webpack option stops all of the date-fns files being imported and chunked.
      const localeToSet = await import(
        /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
        `date-fns/locale/${navigatorLanguage}/index.js`
      ).catch(() => import("date-fns/locale/en-US/index.js"));
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setDefaultLocale(localeToSet.default);
    };

    if (navigatorLanguage) {
      importLocaleFile();
    }
  }, [navigatorLanguage]);

  if (pageProps.fromSupertokens === "needs-refresh") {
    return null;
  }

  console.log("_app locale", router.locale);

  // Don't render the MasterLayout if we are in /auth routes OR not authenticated
  // This last condition is necessary to stop MasterLayout from rendering when the Loading component
  // is rendered which is a full page loading spinner.
  const requiresAuth =
    !router.pathname.startsWith("/auth") &&
    !router.pathname.startsWith("/checkout") &&
    router.pathname !== "/404";

  return (
    <QueryClientProvider client={queryClient}>
      <SuperTokensWrapper>
        <ThemeProvider theme={extendedTheme}>
          <ChakraProvider theme={extendedTheme} colorModeManager={manager}>
            <PostHogProvider client={posthog}>
              <SessionAuth
                requireAuth={requiresAuth}
                doRedirection={requiresAuth}
                // TODO: remove this after fix
                key={router.pathname}
              >
                <Layout>
                  <NextProgress
                    delay={300}
                    options={{ showSpinner: false }}
                    color="#553c9a"
                  />
                  <Script
                    src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
                    strategy="afterInteractive"
                  />
                  <Script id="google-analytics" strategy="afterInteractive">
                    {`
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){window.dataLayer.push(arguments);}
                      gtag('js', new Date());

                      gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}');
                    `}
                  </Script>
                  <Component {...pageProps} />
                </Layout>
              </SessionAuth>
            </PostHogProvider>
          </ChakraProvider>
        </ThemeProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} position="top-right" /> */}
      </SuperTokensWrapper>
    </QueryClientProvider>
  );
};

export default appWithTranslation(MyApp);
