import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Box,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import NextLink from "next/link";
import { useMemo } from "react";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

const Logo = styled.a`
  margin: 0;
  display: block;
  text-align: center;
  font-size: 1.5rem;
  color: #a4a6b3;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
`;

const HeaderMobileLogo: React.FC = () => {
  return (
    <NextLink href="/" legacyBehavior passHref>
      <Logo>
        <Badge
          colorScheme="brand"
          variant="subtle"
          position="absolute"
          top={0}
          right={-4}
          size="xs"
        >
          Beta
        </Badge>
        <Image
          priority
          src="/logo.png"
          alt="WonderMoney"
          width="0"
          height="43"
          sizes="100vw"
          style={{ maxHeight: "43px", width: "auto", height: "100%" }}
        />
      </Logo>
    </NextLink>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const MobileNav = () => {
  const { t } = useTranslation("navigation");

  const NAV_ITEMS: Array<NavItem> = useMemo(
    () => [
      {
        label: t("dashboard"),
        href: "/",
      },
      {
        label: t("accounts"),
        children: [
          {
            label: t("overview"),
            href: "/accounts",
          },
          {
            label: t("transactions"),
            href: "/accounts/transactions",
          },
        ],
      },
      {
        label: t("budgets"),
        children: [
          {
            label: t("overview"),
            href: "/budgets/overview",
          },
        ],
      },
      {
        label: t("portfolio"),
        children: [
          {
            label: t("overview"),
            href: "/portfolio",
          },
          {
            label: t("transactions"),
            href: "/portfolio/transactions",
          },
          {
            label: t("holdings"),
            href: "/portfolio/holdings",
          },
        ],
      },
      {
        label: t("financial-markets"),
        children: [
          {
            label: t("explorer"),
            href: "/financial-markets/explorer",
          },
          {
            label: t("screener"),
            href: "/financial-markets/screener",
          },
          {
            label: t("news"),
            href: "/financial-markets/news",
          },
        ],
      },
      {
        label: t("reports"),
        href: "/reports",
      },
      {
        label: t("settings"),
        href: "/settings",
      },
      {
        label: t("my-account"),
        href: "/my-account",
      },
      {
        label: t("help"),
        href: "https://faq.wonder.money",
      },
    ],
    [t]
  );

  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const MobileHeader: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure();
  const session = useSessionContext();

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <HeaderMobileLogo />
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <Avatar
            name={
              session.loading === false
                ? session.accessTokenPayload?.firstName
                : ""
            }
            size="sm"
          />
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
};

export default MobileHeader;
