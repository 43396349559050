import styled from "@emotion/styled";
// import Notifications from "../Notifications";
import {
  Avatar,
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Icon,
  Link,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { FaChevronRight, FaSignOutAlt } from "react-icons/fa";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import useTransactions from "../../lib/api/account/useTransactions";
import LogoutModal from "../LogoutModal";
import Onboarding from "../OnboardingProgress";

const UserNotifications = styled.div`
  color: #c5c7cd;
  border-left: 1px solid #dfe0eb;
  padding-left: 1rem;
  display: inline-flex;
  align-items: center;
  svg {
    cursor: pointer;
    &:not(:first-of-type) {
      margin-left: 1rem;
    }
  }
  > span {
    position: relative;
    ::after {
      content: "";
      width: 6px;
      height: 6px;
      background-color: ${({ theme }) => theme.colors.blue[500]};
      border-radius: 50%;
      display: block;
      top: 0;
      right: 0;
      position: absolute;
    }
  }
`;

const Breadcrumbs: React.FC = () => {
  const { t } = useTranslation("navigation");
  const router = useRouter();
  const paths = router.pathname.split("/");
  const [, ...crumbs] = paths;
  const crumbsParsed = crumbs.filter((crumb) => !crumb.startsWith("["));
  // const query = router.query;
  // const queryKeys = Object.keys(query);
  // const crumbsParsed = crumbs.map((el) => {
  //   const crumb = el.replace(/\[|\]/g, "");
  //   if (queryKeys.includes(crumb)) {
  //     return query[crumb];
  //   }
  //   return crumb;
  // });
  return (
    <Breadcrumb
      separator={<Icon as={FaChevronRight} w={2} h={2} color="gray.500" />}
      color="gray.500"
      fontSize="sm"
    >
      {crumbsParsed.map((el, i) => {
        return (
          <BreadcrumbItem key={i}>
            <BreadcrumbLink as={NextLink} href="#" legacyBehavior passHref>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {t(el)}
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

const Header: React.FC = () => {
  const { t } = useTranslation("common");
  // const [notificationsOpen, setNotificationsOpen] = useState(false);
  const session = useSessionContext();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const {
    data: unreviewdTransactionsData,
    isLoading: isLoadingUnreviewedTransactionsData,
  } = useTransactions({ unreviewedOnly: true });

  return (
    <Flex
      as="header"
      ml="250px"
      alignItems="center"
      justifyContent="space-between"
      height="3rem"
      px="2rem"
      shadow="sm"
    >
      <Box>
        <Breadcrumbs />
      </Box>
      <Flex display="inline-flex" alignItems="center" gap={12}>
        <Onboarding />
        {!isLoadingUnreviewedTransactionsData &&
          !!unreviewdTransactionsData?.transactions.length && (
            <Flex>
              <NextLink
                href="/accounts/transactions/review"
                legacyBehavior
                passHref
              >
                <Badge as={Link} py={1} px={2}>
                  {unreviewdTransactionsData.transactions.length}{" "}
                  {t("unreviewed-transactions")}
                </Badge>
              </NextLink>
            </Flex>
          )}
        <Flex alignItems="center" gap={4}>
          <Flex alignItems="center" display="inline-flex">
            <Text mr={2}>
              {session.loading === false
                ? session.accessTokenPayload?.firstName
                : ""}
            </Text>
            <Avatar
              name={
                session.loading === false
                  ? session.accessTokenPayload?.firstName
                  : ""
              }
              size="sm"
            />
          </Flex>
          {/* <UserNotifications>
          <Box as="span">
            <Icon
              as={FaBell}
              title={t("notifications")}
              onClick={() => setNotificationsOpen((prev) => !prev)}
            />
            {notificationsOpen && <Notifications />}
          </Box>
        </UserNotifications> */}
          <Icon
            as={FaSignOutAlt}
            title={t("logout")}
            onClick={() => setIsLogoutModalOpen(true)}
            cursor="pointer"
          />
        </Flex>
      </Flex>
      <LogoutModal
        isOpen={isLogoutModalOpen}
        setIsOpen={setIsLogoutModalOpen}
      />
    </Flex>
  );
};

export default Header;
