import { Flex, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import Head from "./Head";
import { HeaderLogo } from "./Sidebar";

const MobileLayout: React.FC = () => {
  const { t } = useTranslation("common");
  return (
    <>
      <Head title="Wonder Money" description="Wonder Money" />
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        px={4}
        gap={24}
      >
        <HeaderLogo isMobile />
        <Flex flexDirection="column" gap={4}>
          <Text>{t("error-mobile-device-1")}</Text>
          <Text>{t("error-mobile-device-2")}</Text>
          <Text>{t("error-mobile-device-3")}</Text>
        </Flex>
        <Link fontSize="sm" color="slate.300" href="mailto:info@wonder.money">
          {t("contact-us")}
        </Link>
      </Flex>
    </>
  );
};

export default MobileLayout;
