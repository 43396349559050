import { Flex, Link } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { usePostHog } from "posthog-js/react";

const TopNotificationBeta: React.FC = () => {
  const { t } = useTranslation("common");
  const posthog = usePostHog();

  return (
    <Flex
      justify="center"
      align="center"
      ml="250px"
      minHeight="30px"
      height="auto"
      bgColor="purple.300"
    >
      <Link
        fontWeight="bold"
        color="gray.700"
        onClick={() => posthog.capture("top_notification_beta_click")}
        href="https://feedback.wonder.money/ideas"
        isExternal
      >
        💰 {t("top-bar-beta-message")}
      </Link>
    </Flex>
  );
};

export default TopNotificationBeta;
