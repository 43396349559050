import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import api from "../api";
import { Category } from "../category/useCategories";

export interface Transaction {
  transactionKey: number;
  transactionDate: string; // date iso
  transactionTypeKey?: {
    name: string;
  };
  accountKey: { accountName: string; accountKey: number };
  notes: string;
  settlementAmount: number;
  editedSettlementAmount?: number;
  settlementCurrencyKey: { code: string };
  appuserSettlementAmount: number;
  appuserCurrencyKey: { code: string };
  balance: number;
  appuserCurrencyBalance: number;
  categories: Category[];
  transactionMerchantKey?: {
    name: string;
  };
  description: string;
  descriptionDirty: string;
  reviewed: boolean;
}

export enum TransactionType {
  CREDIT = "credit",
  DEBIT = "debit",
}

export interface GetTransactionsResponse {
  transactions: Transaction[];
}

interface GetTransactionRequest {
  unreviewedOnly?: boolean;
  fromDate?: Date;
  toDate?: Date;
  limit?: number;
  includeEmptyAccounts?: boolean;
}

const getTransactions = async (params: GetTransactionRequest = {}) => {
  const { data } = await api.get<GetTransactionsResponse>(
    "/v1/account/transaction",
    {
      params,
    }
  );
  return data;
};

export default function useTransactions<R = GetTransactionsResponse>(
  params?: GetTransactionRequest,
  options?: UseQueryOptions<GetTransactionsResponse, unknown, R>
) {
  return useQuery(
    ["accounts-transactions", params],
    () => getTransactions(params),
    options
  );
}
