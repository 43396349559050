import type { AppInfoUserInput } from "supertokens-auth-react/lib/build/types";
import { getApiUrl } from "../api/api";

// FIXME: The only env vars that are actually available in the browser are NODE_ENV and NEXT_PUBLIC_APP_URL
export const websiteDomain =
  process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ||
  process.env.NODE_ENV === "production"
    ? process.env.NEXT_PUBLIC_APP_URL
    : process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
    ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
    : `http://localhost:${process.env.PORT ?? 3000}`;

export const appInfo: AppInfoUserInput = {
  // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
  appName: "WonderMoney",
  apiDomain: getApiUrl(),
  websiteDomain,
  apiBasePath: "/v1/auth",
  websiteBasePath: "/auth",
};
