import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

interface OnboardingState {
  enabled: boolean;
  initialStep: number;
  openProgressMenu: boolean;
  setEnabled: (enabled: boolean) => void;
  setOpenProgressMenu: (open: boolean) => void;
}
const useOnboardingStore = create<OnboardingState>()(
  immer((set) => ({
    enabled: false,
    initialStep: 0,
    openProgressMenu: false,
    setEnabled: (enabled: boolean) =>
      set((state) => {
        state.enabled = enabled;
      }),
    setOpenProgressMenu: (open: boolean) =>
      set((state) => {
        state.openProgressMenu = open;
      }),
  }))
);

export default useOnboardingStore;
