import NextHead from "next/head";

function CustomHead({
  title = "",
  description = "Personal Finance web application",
}) {
  const fullTitle = `WonderMoney${title && ` - ${title}`}`;
  return (
    <NextHead>
      <meta charSet="UTF-8" />
      <title>{fullTitle}</title>
      <meta name="description" content={description || ""} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* Twitter */}
      {/* <meta name="twitter:card" content="summary" key="twcard" />
        <meta name="twitter:creator" content={twitterHandle} key="twhandle" /> */}

      {/* Open Graph */}
      {/* <meta property="og:url" content={currentURL} key="ogurl" />
        <meta property="og:image" content={previewImage} key="ogimage" />
        <meta property="og:site_name" content={siteName} key="ogsitename" />
        <meta property="og:title" content={pageTitle} key="ogtitle" />
        <meta property="og:description" content={description} key="ogdesc" /> */}
      <link rel="icon" type="image/png" href="/favicon.png" />
    </NextHead>
  );
}

export default CustomHead;
