import Axios from "axios";

export const urls = {
  test: `http://localhost:3334`,
  development: "http://localhost:3001",
  production: "https://api.wonder.money",
};

export const getApiUrl = () => {
  return urls[process.env.NODE_ENV];
};

const api = Axios.create({
  baseURL: getApiUrl(),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// export const setToken = (token: string) => {
//   api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
// };

// export const unsetToken = () => {
//   delete api.defaults.headers.common["Authorization"];
// };

// Add auth header - Authorization: Bearer TOKEN
// api.interceptors.request.use(
//   async function (config) {
//     if (config.headers.Authorization) {
//       return Promise.resolve(config);
//     }
//     const session = await getSession();
//     if (session?.user?.accessToken) {
//       config.headers["Authorization"] = `Bearer ${session.user.accessToken}`;
//     }
//     return Promise.resolve(config);
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

export default api;
