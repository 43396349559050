import {
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import type { LoadedSessionContext } from "supertokens-auth-react/lib/build/recipe/session/types";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import useOnboarding from "../lib/api/user/useOnboarding";
import useOnboardingStore from "../store/onboarding/onboarding";

const OnboardingProgress: React.FC = () => {
  const { t } = useTranslation("common");
  const session = useSessionContext();

  const { data: onboardingData, isLoading: isLoadingOnboardingData } =
    useOnboarding((session as LoadedSessionContext).userId, {
      enabled: session.loading === false,
    });
  const { openProgressMenu } = useOnboardingStore();
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(openProgressMenu ?? false);

  useEffect(() => {
    setIsOpen(openProgressMenu);
  }, [openProgressMenu]);

  if (
    isLoadingOnboardingData ||
    !onboardingData ||
    onboardingData?.onboarding.length === 0
  )
    return null;

  const onboardingPercentage = Math.round(
    (onboardingData.onboarding.reduce(
      (acc, curr) => (curr.done ? acc + 1 : acc),
      0
    ) /
      onboardingData.onboarding.length) *
      100
  );

  const onItemClick = (step: number) => {
    setIsOpen(false);
    if (step === 1) {
      router.push("/");
    } else if (step === 2) {
      router.push("/accounts?add=true");
    } else if (step === 3 || step === 4) {
      router.push("/accounts/transactions/review");
    } else if (step === 5) {
      router.push("/portfolio?create=true");
    } else if (step === 6) {
      router.push("/myaccount");
    }
  };

  const isStepDisabled = (step: number) => {
    if (step === 2) {
      // TODO: Check if the user has any unreviewed transactions ?
      return !onboardingData.onboarding[0].done;
    }
    return onboardingData.onboarding[step - 1].done;
  };

  return (
    <Menu closeOnSelect={false} id="onboarding-progress" isOpen={isOpen}>
      <MenuButton
        display="flex"
        as={Button}
        variant="ghost"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Progress
          value={onboardingPercentage}
          width="100px"
          size="sm"
          borderRadius={4}
        />
        <Text as="span" fontSize="xs">
          {onboardingPercentage}% {t("complete")}
        </Text>
      </MenuButton>
      <MenuList>
        {onboardingData.onboarding.map((onboarding) => (
          <MenuItem
            key={onboarding.step}
            onClick={() => onItemClick(onboarding.step)}
            disabled={isStepDisabled(onboarding.step)}
          >
            <Checkbox defaultChecked={onboarding.done} readOnly>
              {onboarding.description}
            </Checkbox>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default OnboardingProgress;
