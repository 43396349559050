import * as Sentry from "@sentry/nextjs";
import { i18n } from "next-i18next";
import Router from "next/router";
import posthog from "posthog-js";
import type { SuperTokensConfig } from "supertokens-auth-react/lib/build/types";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import Session from "supertokens-auth-react/recipe/session";
import ThirdPartyEmailPassword from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import { appInfo } from "./appInfo";

export const frontendConfig = (): SuperTokensConfig => {
  return {
    languageTranslations: {
      translationFunc: i18n?.t.bind(i18n),
    },
    appInfo,
    enableDebugLogs: false,
    recipeList: [
      EmailVerification.init({
        mode: "OPTIONAL",
      }),
      ThirdPartyEmailPassword.init({
        useShadowDom: false,
        signInAndUpFeature: {
          providers: [
            ThirdPartyEmailPassword.Google.init(),
            ThirdPartyEmailPassword.Facebook.init(),
          ],
          signUpForm: {
            termsOfServiceLink:
              "https://faq.wonder.money/about/terms-of-service",
            privacyPolicyLink: "https://faq.wonder.money/about/privacy-policy",
          },
          // disableDefaultUI: true,
        },
        style: `
          [data-supertokens~="superTokensBranding"] {
            display: none;
          }
          [data-supertokens~=container] {
            --palette-background: 51, 51, 51;
            --palette-inputBackground: 41, 41, 41;
            --palette-inputBorder: 41, 41, 41;
            --palette-textTitle: 255, 255, 255;
            --palette-textLabel: 255, 255, 255;
            --palette-textPrimary: 255, 255, 255;
            --palette-error: 173, 46, 46;
            --palette-textInput: 169, 169, 169;
            --palette-textLink: 169, 169, 169;
            --palette-primary: 159,122,234;
            --palette-primaryBorder: 159,122,234;
          }
          [data-supertokens~=providerButton] {
            justify-content: center;
          }
          [data-supertokens~=providerButtonLeft] {
            margin: 0 !important;
          }
            `,
        // eslint-disable-next-line @typescript-eslint/require-await
        onHandleEvent: async (context) => {
          // add analytics here
          if (context.action === "SUCCESS") {
            const user = context.user;
            Sentry.setUser({
              id: user.id,
              email: user.email,
            });
            if (context.isNewUser) {
              // sign up success
              posthog.capture("signup", {
                userId: user.id,
                hasTwoFactor: false,
              });
              window.gtag("event", "sign_up");
            } else {
              // sign in success
              posthog.capture("login", {
                userId: user.id,
                hasTwoFactor: false,
              });
            }
          }
        },
      }),
      Session.init(),
    ],
    // this is so that the SDK uses the next router for navigation
    windowHandler: (oI) => {
      return {
        ...oI,
        location: {
          ...oI.location,
          setHref: (href) => {
            console.log("href", href);
            console.log("Router.locale", Router.locale);
            if (href.includes("google") || href.includes("facebook")) {
              // do nothing for social login
              Router.push(href);
              return;
            }
            const origin = oI.location.getOrigin();
            if (href === "/pt") {
              console.log(
                "href is /pt, redirecting to /pt and updating locale to pt"
              );
              Router.push(href, undefined, { locale: "pt" });
              return;
            }
            if (href === "/") {
              // This is hit after successful login
              console.log(
                "href is /, checking if we need to redirect to another locale"
              );
              const lang = oI.localStorage.getItemSync("prevlang");
              console.log("lang from localstorage", lang);
              if (!lang) {
                console.log("no lang in localstorage, doing nothing");
                Router.push("/");
                return;
              }
              console.log("redirecting to lang", href + lang);
              Router.push(href + lang);
              return;
            }
            console.log("other path");
            const newurl = new URL(origin + href);
            newurl.searchParams.append("prevlang", Router.locale);
            console.log("saving prevlang", Router.locale);
            oI.localStorage.setItemSync("prevlang", Router.locale);
            console.log("newurl", newurl.toString());
            console.log("redirecting to EN");
            Router.push(newurl, undefined, { locale: "en" });
          },
        },
      };
    },
  };
};
