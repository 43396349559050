import { Button, Flex, Text, useColorMode } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Modal from "./ui/Modal";

interface AdminModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const AdminModal: React.FC<AdminModalProps> = ({ isOpen, closeModal }) => {
  const { t, i18n } = useTranslation();
  const { colorMode, toggleColorMode } = useColorMode();
  const router = useRouter();
  const onChangeLang = (lang: string) => {
    i18n.changeLanguage(lang);
    router.push(
      {
        pathname: router.pathname,
        query: router.query,
      },
      router.asPath,
      { locale: lang }
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      contentLabel="Example Modal"
      title={t("delete-transaction")}
    >
      <Flex flexDirection="column" gap={4}>
        <Text as="h2" fontSize="2xl">
          Admin actions
        </Text>
        <Flex gap={4}>
          <Text>
            Current language: {i18n.language} (i18n) {router.locale} (router)
          </Text>
          <Button
            onClick={() => onChangeLang(i18n.language === "en" ? "pt" : "en")}
            variant="outline"
          >
            {t("change-lang")}
          </Button>
        </Flex>
        <Flex gap={4}>
          <Text>Current theme: {colorMode}</Text>
          <Button onClick={toggleColorMode}>
            Toggle {colorMode === "light" ? "Dark" : "Light"}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default AdminModal;
