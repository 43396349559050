import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import api from "../api";

export interface Onboarding {
  step: number;
  description: string;
  done: boolean;
}

interface OnboardingResponse {
  onboarding: Onboarding[];
}

const getOnboarding = async (userId: string) => {
  const { data } = await api.get<OnboardingResponse>(
    `/v1/user/${userId}/onboarding`
  );
  return data;
};

export default function useOnboarding(
  userId: string,
  options?: UseQueryOptions<OnboardingResponse>
) {
  return useQuery(
    ["user-onboarding", userId],
    () => getOnboarding(userId),
    options
  );
}
