import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { isMobile as isMobileDevice } from "react-device-detect";
import { useMedia } from "react-use";
import MasterLayout from "./Master";
import MobileLayout from "./Mobile";

const Layout = ({ children }: PropsWithChildren) => {
  const [blockMobile, setBlockMobile] = useState(false);
  const router = useRouter();
  const posthog = usePostHog();
  const isMobileRes = useMedia("(max-width: 768px)"); // same as "md" in Chakra UI
  console.log("isMobileRes", isMobileRes);

  // This is required so we wait for PostHog to be ready on the client
  useEffect(() => {
    console.log("isMobileDevice", isMobileDevice);
    console.log("mobile feature flag", posthog.isFeatureEnabled("mobile"));
    setBlockMobile(
      (isMobileDevice || isMobileRes) && false // !posthog.isFeatureEnabled("mobile")
    );
  }, [isMobileRes, posthog]);

  // Don't render the MasterLayout if we are in /auth routes OR not authenticated
  // This last condition is necessary to stop MasterLayout from rendering when the Loading component
  // is rendered which is a full page loading spinner.
  const requiresAuth =
    !router.pathname.startsWith("/auth") &&
    !router.pathname.startsWith("/checkout") &&
    router.pathname !== "/404";

  const Layout = blockMobile
    ? MobileLayout
    : requiresAuth
    ? MasterLayout
    : React.Fragment;

  return <Layout>{children}</Layout>;
};

export default Layout;
