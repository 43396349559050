import { Flex } from "@chakra-ui/react";

export default function Container({ isMobile, children }) {
  return (
    <Flex
      pt="1rem"
      pr={isMobile ? "1rem" : "2rem"}
      pl="1rem"
      pb="4rem"
      ml={isMobile ? "0" : "250px"}
      minHeight="calc(100vh - 3rem - 1rem - 30px)" // 3rem = header height, 1rem = padding top, 30px = notification header
      flexDirection="column"
    >
      {children}
    </Flex>
  );
}
