import { Button, Heading } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { usePostHog } from "posthog-js/react";
import { redirectToAuth } from "supertokens-auth-react";
import ThirdPartyEmailPassword from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Modal from "./ui/Modal";

interface LogoutModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const LogoutModal: React.FC<LogoutModalProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const posthog = usePostHog();

  const onLogout = async () => {
    await ThirdPartyEmailPassword.signOut();
    posthog.reset();
    redirectToAuth();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      contentLabel="Example Modal"
      title={t("logout")}
      customStyles={{
        content: {
          width: "400px",
          minWidth: "unset",
        },
      }}
      footer={
        <>
          <Button variant="outline" onClick={onLogout} width="100%">
            Logout
          </Button>
          <Button onClick={() => setIsOpen(false)} width="100%">
            Cancel
          </Button>
        </>
      }
    >
      <Heading size="md" mb={6}>
        {t("logout-confirmation-message")}
      </Heading>
    </Modal>
  );
};

export default LogoutModal;
