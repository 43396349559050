import {
  Colors as ChakraColorsType,
  extendTheme,
  theme as baseTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";

const config = {
  initialColorMode: "system",
};

const colors: ChakraColorsType = {
  brand: {
    100: "#faf5ff",
    200: "#e9d8fd",
    300: "#d6bcfa",
    400: "#b794f4",
    500: "#9f7aea",
    600: "#805ad5",
    700: "#6b46c1",
    800: "#553c9a",
    900: "#44337a",
  },
  slate: {
    50: "#f8fafc",
    100: "#f1f5f9",
    200: "#e2e8f0",
    300: "#cbd5e1",
    400: "#94a3b8",
    500: "#64748b",
    600: "#475569",
    700: "#334155",
    800: "#1e293b",
    900: "#0f172a",
  },
  gray: {
    50: "#f9fafb",
    100: "#f3f4f6",
    200: "#e5e7eb",
    300: "#d1d5db",
    400: "#9ca3af",
    500: "#6b7280",
    600: "#4b5563",
    700: "#374151",
    800: "#1f2937",
    900: "#111827",
  },
  zinc: {
    50: "#fafafa",
    100: "#f4f4f5",
    200: "#e4e4e7",
    300: "#d4d4d8",
    400: "#a1a1aa",
    500: "#71717a",
    600: "#52525b",
    700: "#3f3f46",
    800: "#27272a",
    900: "#18181b",
  },
  neutral: {
    50: "#fafafa",
    100: "#f5f5f5",
    200: "#e5e5e5",
    300: "#d4d4d4",
    400: "#a3a3a3",
    500: "#737373",
    600: "#525252",
    700: "#404040",
    800: "#262626",
    900: "#171717",
  },
  stone: {
    50: "#fafaf9",
    100: "#f5f5f4",
    200: "#e7e5e4",
    300: "#d6d3d1",
    400: "#a8a29e",
    500: "#78716c",
    600: "#57534e",
    700: "#44403c",
    800: "#292524",
    900: "#1c1917",
  },
  red: {
    50: "#fef2f2",
    100: "#fee2e2",
    200: "#fecaca",
    300: "#fca5a5",
    400: "#f87171",
    500: "#ef4444",
    600: "#dc2626",
    700: "#b91c1c",
    800: "#991b1b",
    900: "#7f1d1d",
  },
  orange: {
    50: "#fff7ed",
    100: "#ffedd5",
    200: "#fed7aa",
    300: "#fdba74",
    400: "#fb923c",
    500: "#f97316",
    600: "#ea580c",
    700: "#c2410c",
    800: "#9a3412",
    900: "#7c2d12",
  },
  amber: {
    50: "#fffbeb",
    100: "#fef3c7",
    200: "#fde68a",
    300: "#fcd34d",
    400: "#fbbf24",
    500: "#f59e0b",
    600: "#d97706",
    700: "#b45309",
    800: "#92400e",
    900: "#78350f",
  },
  yellow: {
    50: "#fefce8",
    100: "#fef9c3",
    200: "#fef08a",
    300: "#fde047",
    400: "#facc15",
    500: "#eab308",
    600: "#ca8a04",
    700: "#a16207",
    800: "#854d0e",
    900: "#713f12",
  },
  lime: {
    50: "#f7fee7",
    100: "#ecfccb",
    200: "#d9f99d",
    300: "#bef264",
    400: "#a3e635",
    500: "#84cc16",
    600: "#65a30d",
    700: "#4d7c0f",
    800: "#3f6212",
    900: "#365314",
  },
  green: {
    50: "#f0fdf4",
    100: "#dcfce7",
    200: "#bbf7d0",
    300: "#86efac",
    400: "#4ade80",
    500: "#22c55e",
    600: "#16a34a",
    700: "#15803d",
    800: "#166534",
    900: "#14532d",
  },
  emerald: {
    50: "#ecfdf5",
    100: "#d1fae5",
    200: "#a7f3d0",
    300: "#6ee7b7",
    400: "#34d399",
    500: "#10b981",
    600: "#059669",
    700: "#047857",
    800: "#065f46",
    900: "#064e3b",
  },
  teal: {
    50: "#f0fdfa",
    100: "#ccfbf1",
    200: "#99f6e4",
    300: "#5eead4",
    400: "#2dd4bf",
    500: "#14b8a6",
    600: "#0d9488",
    700: "#0f766e",
    800: "#115e59",
    900: "#134e4a",
  },
  cyan: {
    50: "#ecfeff",
    100: "#cffafe",
    200: "#a5f3fc",
    300: "#67e8f9",
    400: "#22d3ee",
    500: "#06b6d4",
    600: "#0891b2",
    700: "#0e7490",
    800: "#155e75",
    900: "#164e63",
  },
  sky: {
    50: "#f0f9ff",
    100: "#e0f2fe",
    200: "#bae6fd",
    300: "#7dd3fc",
    400: "#38bdf8",
    500: "#0ea5e9",
    600: "#0284c7",
    700: "#0369a1",
    800: "#075985",
    900: "#0c4a6e",
  },
  blue: {
    50: "#eff6ff",
    100: "#dbeafe",
    200: "#bfdbfe",
    300: "#93c5fd",
    400: "#60a5fa",
    500: "#3b82f6",
    600: "#2563eb",
    700: "#1d4ed8",
    800: "#1e40af",
    900: "#1e3a8a",
  },
  indigo: {
    50: "#eef2ff",
    100: "#e0e7ff",
    200: "#c7d2fe",
    300: "#a5b4fc",
    400: "#818cf8",
    500: "#6366f1",
    600: "#4f46e5",
    700: "#4338ca",
    800: "#3730a3",
    900: "#312e81",
  },
  violet: {
    50: "#f5f3ff",
    100: "#ede9fe",
    200: "#ddd6fe",
    300: "#c4b5fd",
    400: "#a78bfa",
    500: "#8b5cf6",
    600: "#7c3aed",
    700: "#6d28d9",
    800: "#5b21b6",
    900: "#4c1d95",
  },
  purple: {
    50: "#faf5ff",
    100: "#f3e8ff",
    200: "#e9d5ff",
    300: "#d8b4fe",
    400: "#c084fc",
    500: "#a855f7",
    600: "#9333ea",
    700: "#7e22ce",
    800: "#6b21a8",
    900: "#581c87",
  },
  fuchsia: {
    50: "#fdf4ff",
    100: "#fae8ff",
    200: "#f5d0fe",
    300: "#f0abfc",
    400: "#e879f9",
    500: "#d946ef",
    600: "#c026d3",
    700: "#a21caf",
    800: "#86198f",
    900: "#701a75",
  },
  pink: {
    50: "#fdf2f8",
    100: "#fce7f3",
    200: "#fbcfe8",
    300: "#f9a8d4",
    400: "#f472b6",
    500: "#ec4899",
    600: "#db2777",
    700: "#be185d",
    800: "#9d174d",
    900: "#831843",
  },
  rose: {
    50: "#fff1f2",
    100: "#ffe4e6",
    200: "#fecdd3",
    300: "#fda4af",
    400: "#fb7185",
    500: "#f43f5e",
    600: "#e11d48",
    700: "#be123c",
    800: "#9f1239",
    900: "#881337",
  },
};

const fonts = {
  heading: "Bitter, sans-serif",
  body: "Inter, sans-serif",
};

export const extendedTheme = extendTheme(
  config,
  {
    colors,
    fonts,
  },
  withDefaultColorScheme({ colorScheme: "brand" }),
  baseTheme // optional
);

// For Emotion
// Not working
// type Theme = typeof extendedTheme;
// export type { Theme };

// Because there's no proper TS support for extendTheme, lets hardcode the interface
// https://github.com/chakra-ui/chakra-ui/issues/6202

export type { TopLevel as Theme };
export interface TopLevel {
  direction: string;
  breakpoints: Blur;
  zIndices: ZIndices;
  radii: Radii;
  blur: Blur;
  colors: Colors;
  letterSpacings: LetterSpacings;
  lineHeights: LineHeights;
  fontWeights: FontWeights;
  fonts: Fonts;
  fontSizes: { [key: string]: string };
  sizes: TopLevelSizes;
  shadows: Shadows;
  space: { [key: string]: string };
  borders: Borders;
  transition: Transition;
  components: Components;
  styles: Styles;
  config: Config;
  initialColorMode: string;
  useSystemColorMode: boolean;
  __cssVars: CSSVars;
  __cssMap: CSSMap;
  __breakpoints: Breakpoints;
}

export interface Breakpoints {
  keys: Styles;
  normalized: string[];
  asObject: Blur;
  asArray: string[];
  details: Detail[];
  media: Array<null | string>;
}

export interface Blur {
  base: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  "2xl": string;
  none?: number;
  "3xl"?: string;
}

export interface Detail {
  breakpoint: string;
  minW: string;
  maxW?: string;
  maxWQuery: string;
  minWQuery: string;
  minMaxQuery: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Styles {}

export interface CSSMap {
  "colors.transparent": LivingstoneSouthernWhiteFacedOwl;
  "colors.current": LivingstoneSouthernWhiteFacedOwl;
  "colors.black": LivingstoneSouthernWhiteFacedOwl;
  "colors.white": LivingstoneSouthernWhiteFacedOwl;
  "colors.whiteAlpha.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.whiteAlpha.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.whiteAlpha.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.whiteAlpha.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.whiteAlpha.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.whiteAlpha.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.whiteAlpha.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.whiteAlpha.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.whiteAlpha.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.whiteAlpha.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.blackAlpha.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.blackAlpha.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.blackAlpha.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.blackAlpha.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.blackAlpha.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.blackAlpha.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.blackAlpha.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.blackAlpha.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.blackAlpha.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.blackAlpha.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.gray.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.gray.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.gray.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.gray.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.gray.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.gray.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.gray.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.gray.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.gray.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.gray.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.red.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.red.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.red.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.red.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.red.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.red.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.red.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.red.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.red.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.red.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.orange.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.orange.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.orange.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.orange.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.orange.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.orange.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.orange.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.orange.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.orange.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.orange.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.yellow.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.yellow.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.yellow.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.yellow.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.yellow.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.yellow.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.yellow.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.yellow.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.yellow.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.yellow.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.green.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.green.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.green.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.green.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.green.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.green.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.green.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.green.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.green.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.green.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.teal.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.teal.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.teal.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.teal.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.teal.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.teal.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.teal.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.teal.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.teal.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.teal.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.blue.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.blue.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.blue.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.blue.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.blue.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.blue.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.blue.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.blue.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.blue.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.blue.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.cyan.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.cyan.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.cyan.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.cyan.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.cyan.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.cyan.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.cyan.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.cyan.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.cyan.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.cyan.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.purple.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.purple.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.purple.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.purple.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.purple.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.purple.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.purple.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.purple.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.purple.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.purple.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.pink.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.pink.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.pink.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.pink.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.pink.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.pink.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.pink.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.pink.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.pink.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.pink.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.linkedin.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.linkedin.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.linkedin.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.linkedin.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.linkedin.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.linkedin.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.linkedin.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.linkedin.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.linkedin.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.linkedin.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.facebook.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.facebook.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.facebook.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.facebook.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.facebook.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.facebook.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.facebook.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.facebook.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.facebook.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.facebook.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.messenger.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.messenger.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.messenger.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.messenger.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.messenger.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.messenger.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.messenger.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.messenger.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.messenger.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.messenger.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.whatsapp.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.whatsapp.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.whatsapp.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.whatsapp.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.whatsapp.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.whatsapp.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.whatsapp.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.whatsapp.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.whatsapp.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.whatsapp.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.twitter.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.twitter.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.twitter.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.twitter.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.twitter.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.twitter.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.twitter.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.twitter.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.twitter.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.twitter.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.telegram.50": LivingstoneSouthernWhiteFacedOwl;
  "colors.telegram.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.telegram.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.telegram.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.telegram.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.telegram.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.telegram.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.telegram.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.telegram.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.telegram.900": LivingstoneSouthernWhiteFacedOwl;
  "colors.brand.100": LivingstoneSouthernWhiteFacedOwl;
  "colors.brand.200": LivingstoneSouthernWhiteFacedOwl;
  "colors.brand.300": LivingstoneSouthernWhiteFacedOwl;
  "colors.brand.400": LivingstoneSouthernWhiteFacedOwl;
  "colors.brand.500": LivingstoneSouthernWhiteFacedOwl;
  "colors.brand.600": LivingstoneSouthernWhiteFacedOwl;
  "colors.brand.700": LivingstoneSouthernWhiteFacedOwl;
  "colors.brand.800": LivingstoneSouthernWhiteFacedOwl;
  "colors.brand.900": LivingstoneSouthernWhiteFacedOwl;
  "borders.none": HammerfestPonies;
  "borders.1px": LivingstoneSouthernWhiteFacedOwl;
  "borders.2px": LivingstoneSouthernWhiteFacedOwl;
  "borders.4px": LivingstoneSouthernWhiteFacedOwl;
  "borders.8px": LivingstoneSouthernWhiteFacedOwl;
  "fonts.heading": LivingstoneSouthernWhiteFacedOwl;
  "fonts.body": LivingstoneSouthernWhiteFacedOwl;
  "fonts.mono": LivingstoneSouthernWhiteFacedOwl;
  "fontSizes.xs": LivingstoneSouthernWhiteFacedOwl;
  "fontSizes.sm": LivingstoneSouthernWhiteFacedOwl;
  "fontSizes.md": LivingstoneSouthernWhiteFacedOwl;
  "fontSizes.lg": LivingstoneSouthernWhiteFacedOwl;
  "fontSizes.xl": LivingstoneSouthernWhiteFacedOwl;
  "fontSizes.2xl": LivingstoneSouthernWhiteFacedOwl;
  "fontSizes.3xl": LivingstoneSouthernWhiteFacedOwl;
  "fontSizes.4xl": LivingstoneSouthernWhiteFacedOwl;
  "fontSizes.5xl": LivingstoneSouthernWhiteFacedOwl;
  "fontSizes.6xl": LivingstoneSouthernWhiteFacedOwl;
  "fontSizes.7xl": LivingstoneSouthernWhiteFacedOwl;
  "fontSizes.8xl": LivingstoneSouthernWhiteFacedOwl;
  "fontSizes.9xl": LivingstoneSouthernWhiteFacedOwl;
  "fontWeights.hairline": HammerfestPonies;
  "fontWeights.thin": HammerfestPonies;
  "fontWeights.light": HammerfestPonies;
  "fontWeights.normal": HammerfestPonies;
  "fontWeights.medium": HammerfestPonies;
  "fontWeights.semibold": HammerfestPonies;
  "fontWeights.bold": HammerfestPonies;
  "fontWeights.extrabold": HammerfestPonies;
  "fontWeights.black": HammerfestPonies;
  "letterSpacings.tighter": LivingstoneSouthernWhiteFacedOwl;
  "letterSpacings.tight": LivingstoneSouthernWhiteFacedOwl;
  "letterSpacings.normal": LivingstoneSouthernWhiteFacedOwl;
  "letterSpacings.wide": LivingstoneSouthernWhiteFacedOwl;
  "letterSpacings.wider": LivingstoneSouthernWhiteFacedOwl;
  "letterSpacings.widest": LivingstoneSouthernWhiteFacedOwl;
  "lineHeights.3": LivingstoneSouthernWhiteFacedOwl;
  "lineHeights.4": LivingstoneSouthernWhiteFacedOwl;
  "lineHeights.5": LivingstoneSouthernWhiteFacedOwl;
  "lineHeights.6": LivingstoneSouthernWhiteFacedOwl;
  "lineHeights.7": LivingstoneSouthernWhiteFacedOwl;
  "lineHeights.8": LivingstoneSouthernWhiteFacedOwl;
  "lineHeights.9": LivingstoneSouthernWhiteFacedOwl;
  "lineHeights.10": LivingstoneSouthernWhiteFacedOwl;
  "lineHeights.normal": LivingstoneSouthernWhiteFacedOwl;
  "lineHeights.none": HammerfestPonies;
  "lineHeights.shorter": HammerfestPonies;
  "lineHeights.short": HammerfestPonies;
  "lineHeights.base": HammerfestPonies;
  "lineHeights.tall": HammerfestPonies;
  "lineHeights.taller": LivingstoneSouthernWhiteFacedOwl;
  "radii.none": LivingstoneSouthernWhiteFacedOwl;
  "radii.sm": LivingstoneSouthernWhiteFacedOwl;
  "radii.base": LivingstoneSouthernWhiteFacedOwl;
  "radii.md": LivingstoneSouthernWhiteFacedOwl;
  "radii.lg": LivingstoneSouthernWhiteFacedOwl;
  "radii.xl": LivingstoneSouthernWhiteFacedOwl;
  "radii.2xl": LivingstoneSouthernWhiteFacedOwl;
  "radii.3xl": LivingstoneSouthernWhiteFacedOwl;
  "radii.full": LivingstoneSouthernWhiteFacedOwl;
  "space.-1": LivingstoneSouthernWhiteFacedOwl;
  "space.1": LivingstoneSouthernWhiteFacedOwl;
  "space.-2": LivingstoneSouthernWhiteFacedOwl;
  "space.2": LivingstoneSouthernWhiteFacedOwl;
  "space.-3": LivingstoneSouthernWhiteFacedOwl;
  "space.3": LivingstoneSouthernWhiteFacedOwl;
  "space.-4": LivingstoneSouthernWhiteFacedOwl;
  "space.4": LivingstoneSouthernWhiteFacedOwl;
  "space.-5": LivingstoneSouthernWhiteFacedOwl;
  "space.5": LivingstoneSouthernWhiteFacedOwl;
  "space.-6": LivingstoneSouthernWhiteFacedOwl;
  "space.6": LivingstoneSouthernWhiteFacedOwl;
  "space.-7": LivingstoneSouthernWhiteFacedOwl;
  "space.7": LivingstoneSouthernWhiteFacedOwl;
  "space.-8": LivingstoneSouthernWhiteFacedOwl;
  "space.8": LivingstoneSouthernWhiteFacedOwl;
  "space.-9": LivingstoneSouthernWhiteFacedOwl;
  "space.9": LivingstoneSouthernWhiteFacedOwl;
  "space.-10": LivingstoneSouthernWhiteFacedOwl;
  "space.10": LivingstoneSouthernWhiteFacedOwl;
  "space.-12": LivingstoneSouthernWhiteFacedOwl;
  "space.12": LivingstoneSouthernWhiteFacedOwl;
  "space.-14": LivingstoneSouthernWhiteFacedOwl;
  "space.14": LivingstoneSouthernWhiteFacedOwl;
  "space.-16": LivingstoneSouthernWhiteFacedOwl;
  "space.16": LivingstoneSouthernWhiteFacedOwl;
  "space.-20": LivingstoneSouthernWhiteFacedOwl;
  "space.20": LivingstoneSouthernWhiteFacedOwl;
  "space.-24": LivingstoneSouthernWhiteFacedOwl;
  "space.24": LivingstoneSouthernWhiteFacedOwl;
  "space.-28": LivingstoneSouthernWhiteFacedOwl;
  "space.28": LivingstoneSouthernWhiteFacedOwl;
  "space.-32": LivingstoneSouthernWhiteFacedOwl;
  "space.32": LivingstoneSouthernWhiteFacedOwl;
  "space.-36": LivingstoneSouthernWhiteFacedOwl;
  "space.36": LivingstoneSouthernWhiteFacedOwl;
  "space.-40": LivingstoneSouthernWhiteFacedOwl;
  "space.40": LivingstoneSouthernWhiteFacedOwl;
  "space.-44": LivingstoneSouthernWhiteFacedOwl;
  "space.44": LivingstoneSouthernWhiteFacedOwl;
  "space.-48": LivingstoneSouthernWhiteFacedOwl;
  "space.48": LivingstoneSouthernWhiteFacedOwl;
  "space.-52": LivingstoneSouthernWhiteFacedOwl;
  "space.52": LivingstoneSouthernWhiteFacedOwl;
  "space.-56": LivingstoneSouthernWhiteFacedOwl;
  "space.56": LivingstoneSouthernWhiteFacedOwl;
  "space.-60": LivingstoneSouthernWhiteFacedOwl;
  "space.60": LivingstoneSouthernWhiteFacedOwl;
  "space.-64": LivingstoneSouthernWhiteFacedOwl;
  "space.64": LivingstoneSouthernWhiteFacedOwl;
  "space.-72": LivingstoneSouthernWhiteFacedOwl;
  "space.72": LivingstoneSouthernWhiteFacedOwl;
  "space.-80": LivingstoneSouthernWhiteFacedOwl;
  "space.80": LivingstoneSouthernWhiteFacedOwl;
  "space.-96": LivingstoneSouthernWhiteFacedOwl;
  "space.96": LivingstoneSouthernWhiteFacedOwl;
  "space.-px": LivingstoneSouthernWhiteFacedOwl;
  "space.px": LivingstoneSouthernWhiteFacedOwl;
  "space.-0.5": LivingstoneSouthernWhiteFacedOwl;
  "space.0.5": LivingstoneSouthernWhiteFacedOwl;
  "space.-1.5": LivingstoneSouthernWhiteFacedOwl;
  "space.1.5": LivingstoneSouthernWhiteFacedOwl;
  "space.-2.5": LivingstoneSouthernWhiteFacedOwl;
  "space.2.5": LivingstoneSouthernWhiteFacedOwl;
  "space.-3.5": LivingstoneSouthernWhiteFacedOwl;
  "space.3.5": LivingstoneSouthernWhiteFacedOwl;
  "shadows.xs": LivingstoneSouthernWhiteFacedOwl;
  "shadows.sm": LivingstoneSouthernWhiteFacedOwl;
  "shadows.base": LivingstoneSouthernWhiteFacedOwl;
  "shadows.md": LivingstoneSouthernWhiteFacedOwl;
  "shadows.lg": LivingstoneSouthernWhiteFacedOwl;
  "shadows.xl": LivingstoneSouthernWhiteFacedOwl;
  "shadows.2xl": LivingstoneSouthernWhiteFacedOwl;
  "shadows.outline": LivingstoneSouthernWhiteFacedOwl;
  "shadows.inner": LivingstoneSouthernWhiteFacedOwl;
  "shadows.none": LivingstoneSouthernWhiteFacedOwl;
  "shadows.dark-lg": LivingstoneSouthernWhiteFacedOwl;
  "sizes.1": LivingstoneSouthernWhiteFacedOwl;
  "sizes.2": LivingstoneSouthernWhiteFacedOwl;
  "sizes.3": LivingstoneSouthernWhiteFacedOwl;
  "sizes.4": LivingstoneSouthernWhiteFacedOwl;
  "sizes.5": LivingstoneSouthernWhiteFacedOwl;
  "sizes.6": LivingstoneSouthernWhiteFacedOwl;
  "sizes.7": LivingstoneSouthernWhiteFacedOwl;
  "sizes.8": LivingstoneSouthernWhiteFacedOwl;
  "sizes.9": LivingstoneSouthernWhiteFacedOwl;
  "sizes.10": LivingstoneSouthernWhiteFacedOwl;
  "sizes.12": LivingstoneSouthernWhiteFacedOwl;
  "sizes.14": LivingstoneSouthernWhiteFacedOwl;
  "sizes.16": LivingstoneSouthernWhiteFacedOwl;
  "sizes.20": LivingstoneSouthernWhiteFacedOwl;
  "sizes.24": LivingstoneSouthernWhiteFacedOwl;
  "sizes.28": LivingstoneSouthernWhiteFacedOwl;
  "sizes.32": LivingstoneSouthernWhiteFacedOwl;
  "sizes.36": LivingstoneSouthernWhiteFacedOwl;
  "sizes.40": LivingstoneSouthernWhiteFacedOwl;
  "sizes.44": LivingstoneSouthernWhiteFacedOwl;
  "sizes.48": LivingstoneSouthernWhiteFacedOwl;
  "sizes.52": LivingstoneSouthernWhiteFacedOwl;
  "sizes.56": LivingstoneSouthernWhiteFacedOwl;
  "sizes.60": LivingstoneSouthernWhiteFacedOwl;
  "sizes.64": LivingstoneSouthernWhiteFacedOwl;
  "sizes.72": LivingstoneSouthernWhiteFacedOwl;
  "sizes.80": LivingstoneSouthernWhiteFacedOwl;
  "sizes.96": LivingstoneSouthernWhiteFacedOwl;
  "sizes.px": LivingstoneSouthernWhiteFacedOwl;
  "sizes.0.5": LivingstoneSouthernWhiteFacedOwl;
  "sizes.1.5": LivingstoneSouthernWhiteFacedOwl;
  "sizes.2.5": LivingstoneSouthernWhiteFacedOwl;
  "sizes.3.5": LivingstoneSouthernWhiteFacedOwl;
  "sizes.max": LivingstoneSouthernWhiteFacedOwl;
  "sizes.min": LivingstoneSouthernWhiteFacedOwl;
  "sizes.full": LivingstoneSouthernWhiteFacedOwl;
  "sizes.3xs": LivingstoneSouthernWhiteFacedOwl;
  "sizes.2xs": LivingstoneSouthernWhiteFacedOwl;
  "sizes.xs": LivingstoneSouthernWhiteFacedOwl;
  "sizes.sm": LivingstoneSouthernWhiteFacedOwl;
  "sizes.md": LivingstoneSouthernWhiteFacedOwl;
  "sizes.lg": LivingstoneSouthernWhiteFacedOwl;
  "sizes.xl": LivingstoneSouthernWhiteFacedOwl;
  "sizes.2xl": LivingstoneSouthernWhiteFacedOwl;
  "sizes.3xl": LivingstoneSouthernWhiteFacedOwl;
  "sizes.4xl": LivingstoneSouthernWhiteFacedOwl;
  "sizes.5xl": LivingstoneSouthernWhiteFacedOwl;
  "sizes.6xl": LivingstoneSouthernWhiteFacedOwl;
  "sizes.7xl": LivingstoneSouthernWhiteFacedOwl;
  "sizes.8xl": LivingstoneSouthernWhiteFacedOwl;
  "sizes.container.sm": LivingstoneSouthernWhiteFacedOwl;
  "sizes.container.md": LivingstoneSouthernWhiteFacedOwl;
  "sizes.container.lg": LivingstoneSouthernWhiteFacedOwl;
  "sizes.container.xl": LivingstoneSouthernWhiteFacedOwl;
  "zIndices.hide": HammerfestPonies;
  "zIndices.auto": LivingstoneSouthernWhiteFacedOwl;
  "zIndices.base": HammerfestPonies;
  "zIndices.docked": HammerfestPonies;
  "zIndices.dropdown": HammerfestPonies;
  "zIndices.sticky": HammerfestPonies;
  "zIndices.banner": HammerfestPonies;
  "zIndices.overlay": HammerfestPonies;
  "zIndices.modal": HammerfestPonies;
  "zIndices.popover": HammerfestPonies;
  "zIndices.skipLink": HammerfestPonies;
  "zIndices.toast": HammerfestPonies;
  "zIndices.tooltip": HammerfestPonies;
  "transition.property.common": LivingstoneSouthernWhiteFacedOwl;
  "transition.property.colors": LivingstoneSouthernWhiteFacedOwl;
  "transition.property.dimensions": LivingstoneSouthernWhiteFacedOwl;
  "transition.property.position": LivingstoneSouthernWhiteFacedOwl;
  "transition.property.background": LivingstoneSouthernWhiteFacedOwl;
  "transition.easing.ease-in": LivingstoneSouthernWhiteFacedOwl;
  "transition.easing.ease-out": LivingstoneSouthernWhiteFacedOwl;
  "transition.easing.ease-in-out": LivingstoneSouthernWhiteFacedOwl;
  "transition.duration.ultra-fast": LivingstoneSouthernWhiteFacedOwl;
  "transition.duration.faster": LivingstoneSouthernWhiteFacedOwl;
  "transition.duration.fast": LivingstoneSouthernWhiteFacedOwl;
  "transition.duration.normal": LivingstoneSouthernWhiteFacedOwl;
  "transition.duration.slow": LivingstoneSouthernWhiteFacedOwl;
  "transition.duration.slower": LivingstoneSouthernWhiteFacedOwl;
  "transition.duration.ultra-slow": LivingstoneSouthernWhiteFacedOwl;
  "blur.none": HammerfestPonies;
  "blur.sm": LivingstoneSouthernWhiteFacedOwl;
  "blur.base": LivingstoneSouthernWhiteFacedOwl;
  "blur.md": LivingstoneSouthernWhiteFacedOwl;
  "blur.lg": LivingstoneSouthernWhiteFacedOwl;
  "blur.xl": LivingstoneSouthernWhiteFacedOwl;
  "blur.2xl": LivingstoneSouthernWhiteFacedOwl;
  "blur.3xl": LivingstoneSouthernWhiteFacedOwl;
}

export interface LivingstoneSouthernWhiteFacedOwl {
  value: string;
  var: string;
  varRef: string;
}

export interface HammerfestPonies {
  value: number;
  var: string;
  varRef: string;
}

export interface CSSVars {
  "--chakra-ring-inset": string;
  "--chakra-ring-offset-width": string;
  "--chakra-ring-offset-color": string;
  "--chakra-ring-color": string;
  "--chakra-ring-offset-shadow": string;
  "--chakra-ring-shadow": string;
  "--chakra-space-x-reverse": string;
  "--chakra-space-y-reverse": string;
  "--chakra-colors-transparent": string;
  "--chakra-colors-current": string;
  "--chakra-colors-black": string;
  "--chakra-colors-white": string;
  "--chakra-colors-whiteAlpha-50": string;
  "--chakra-colors-whiteAlpha-100": string;
  "--chakra-colors-whiteAlpha-200": string;
  "--chakra-colors-whiteAlpha-300": string;
  "--chakra-colors-whiteAlpha-400": string;
  "--chakra-colors-whiteAlpha-500": string;
  "--chakra-colors-whiteAlpha-600": string;
  "--chakra-colors-whiteAlpha-700": string;
  "--chakra-colors-whiteAlpha-800": string;
  "--chakra-colors-whiteAlpha-900": string;
  "--chakra-colors-blackAlpha-50": string;
  "--chakra-colors-blackAlpha-100": string;
  "--chakra-colors-blackAlpha-200": string;
  "--chakra-colors-blackAlpha-300": string;
  "--chakra-colors-blackAlpha-400": string;
  "--chakra-colors-blackAlpha-500": string;
  "--chakra-colors-blackAlpha-600": string;
  "--chakra-colors-blackAlpha-700": string;
  "--chakra-colors-blackAlpha-800": string;
  "--chakra-colors-blackAlpha-900": string;
  "--chakra-colors-gray-50": string;
  "--chakra-colors-gray-100": string;
  "--chakra-colors-gray-200": string;
  "--chakra-colors-gray-300": string;
  "--chakra-colors-gray-400": string;
  "--chakra-colors-gray-500": string;
  "--chakra-colors-gray-600": string;
  "--chakra-colors-gray-700": string;
  "--chakra-colors-gray-800": string;
  "--chakra-colors-gray-900": string;
  "--chakra-colors-red-50": string;
  "--chakra-colors-red-100": string;
  "--chakra-colors-red-200": string;
  "--chakra-colors-red-300": string;
  "--chakra-colors-red-400": string;
  "--chakra-colors-red-500": string;
  "--chakra-colors-red-600": string;
  "--chakra-colors-red-700": string;
  "--chakra-colors-red-800": string;
  "--chakra-colors-red-900": string;
  "--chakra-colors-orange-50": string;
  "--chakra-colors-orange-100": string;
  "--chakra-colors-orange-200": string;
  "--chakra-colors-orange-300": string;
  "--chakra-colors-orange-400": string;
  "--chakra-colors-orange-500": string;
  "--chakra-colors-orange-600": string;
  "--chakra-colors-orange-700": string;
  "--chakra-colors-orange-800": string;
  "--chakra-colors-orange-900": string;
  "--chakra-colors-yellow-50": string;
  "--chakra-colors-yellow-100": string;
  "--chakra-colors-yellow-200": string;
  "--chakra-colors-yellow-300": string;
  "--chakra-colors-yellow-400": string;
  "--chakra-colors-yellow-500": string;
  "--chakra-colors-yellow-600": string;
  "--chakra-colors-yellow-700": string;
  "--chakra-colors-yellow-800": string;
  "--chakra-colors-yellow-900": string;
  "--chakra-colors-green-50": string;
  "--chakra-colors-green-100": string;
  "--chakra-colors-green-200": string;
  "--chakra-colors-green-300": string;
  "--chakra-colors-green-400": string;
  "--chakra-colors-green-500": string;
  "--chakra-colors-green-600": string;
  "--chakra-colors-green-700": string;
  "--chakra-colors-green-800": string;
  "--chakra-colors-green-900": string;
  "--chakra-colors-teal-50": string;
  "--chakra-colors-teal-100": string;
  "--chakra-colors-teal-200": string;
  "--chakra-colors-teal-300": string;
  "--chakra-colors-teal-400": string;
  "--chakra-colors-teal-500": string;
  "--chakra-colors-teal-600": string;
  "--chakra-colors-teal-700": string;
  "--chakra-colors-teal-800": string;
  "--chakra-colors-teal-900": string;
  "--chakra-colors-blue-50": string;
  "--chakra-colors-blue-100": string;
  "--chakra-colors-blue-200": string;
  "--chakra-colors-blue-300": string;
  "--chakra-colors-blue-400": string;
  "--chakra-colors-blue-500": string;
  "--chakra-colors-blue-600": string;
  "--chakra-colors-blue-700": string;
  "--chakra-colors-blue-800": string;
  "--chakra-colors-blue-900": string;
  "--chakra-colors-cyan-50": string;
  "--chakra-colors-cyan-100": string;
  "--chakra-colors-cyan-200": string;
  "--chakra-colors-cyan-300": string;
  "--chakra-colors-cyan-400": string;
  "--chakra-colors-cyan-500": string;
  "--chakra-colors-cyan-600": string;
  "--chakra-colors-cyan-700": string;
  "--chakra-colors-cyan-800": string;
  "--chakra-colors-cyan-900": string;
  "--chakra-colors-purple-50": string;
  "--chakra-colors-purple-100": string;
  "--chakra-colors-purple-200": string;
  "--chakra-colors-purple-300": string;
  "--chakra-colors-purple-400": string;
  "--chakra-colors-purple-500": string;
  "--chakra-colors-purple-600": string;
  "--chakra-colors-purple-700": string;
  "--chakra-colors-purple-800": string;
  "--chakra-colors-purple-900": string;
  "--chakra-colors-pink-50": string;
  "--chakra-colors-pink-100": string;
  "--chakra-colors-pink-200": string;
  "--chakra-colors-pink-300": string;
  "--chakra-colors-pink-400": string;
  "--chakra-colors-pink-500": string;
  "--chakra-colors-pink-600": string;
  "--chakra-colors-pink-700": string;
  "--chakra-colors-pink-800": string;
  "--chakra-colors-pink-900": string;
  "--chakra-colors-linkedin-50": string;
  "--chakra-colors-linkedin-100": string;
  "--chakra-colors-linkedin-200": string;
  "--chakra-colors-linkedin-300": string;
  "--chakra-colors-linkedin-400": string;
  "--chakra-colors-linkedin-500": string;
  "--chakra-colors-linkedin-600": string;
  "--chakra-colors-linkedin-700": string;
  "--chakra-colors-linkedin-800": string;
  "--chakra-colors-linkedin-900": string;
  "--chakra-colors-facebook-50": string;
  "--chakra-colors-facebook-100": string;
  "--chakra-colors-facebook-200": string;
  "--chakra-colors-facebook-300": string;
  "--chakra-colors-facebook-400": string;
  "--chakra-colors-facebook-500": string;
  "--chakra-colors-facebook-600": string;
  "--chakra-colors-facebook-700": string;
  "--chakra-colors-facebook-800": string;
  "--chakra-colors-facebook-900": string;
  "--chakra-colors-messenger-50": string;
  "--chakra-colors-messenger-100": string;
  "--chakra-colors-messenger-200": string;
  "--chakra-colors-messenger-300": string;
  "--chakra-colors-messenger-400": string;
  "--chakra-colors-messenger-500": string;
  "--chakra-colors-messenger-600": string;
  "--chakra-colors-messenger-700": string;
  "--chakra-colors-messenger-800": string;
  "--chakra-colors-messenger-900": string;
  "--chakra-colors-whatsapp-50": string;
  "--chakra-colors-whatsapp-100": string;
  "--chakra-colors-whatsapp-200": string;
  "--chakra-colors-whatsapp-300": string;
  "--chakra-colors-whatsapp-400": string;
  "--chakra-colors-whatsapp-500": string;
  "--chakra-colors-whatsapp-600": string;
  "--chakra-colors-whatsapp-700": string;
  "--chakra-colors-whatsapp-800": string;
  "--chakra-colors-whatsapp-900": string;
  "--chakra-colors-twitter-50": string;
  "--chakra-colors-twitter-100": string;
  "--chakra-colors-twitter-200": string;
  "--chakra-colors-twitter-300": string;
  "--chakra-colors-twitter-400": string;
  "--chakra-colors-twitter-500": string;
  "--chakra-colors-twitter-600": string;
  "--chakra-colors-twitter-700": string;
  "--chakra-colors-twitter-800": string;
  "--chakra-colors-twitter-900": string;
  "--chakra-colors-telegram-50": string;
  "--chakra-colors-telegram-100": string;
  "--chakra-colors-telegram-200": string;
  "--chakra-colors-telegram-300": string;
  "--chakra-colors-telegram-400": string;
  "--chakra-colors-telegram-500": string;
  "--chakra-colors-telegram-600": string;
  "--chakra-colors-telegram-700": string;
  "--chakra-colors-telegram-800": string;
  "--chakra-colors-telegram-900": string;
  "--chakra-colors-brand-100": string;
  "--chakra-colors-brand-200": string;
  "--chakra-colors-brand-300": string;
  "--chakra-colors-brand-400": string;
  "--chakra-colors-brand-500": string;
  "--chakra-colors-brand-600": string;
  "--chakra-colors-brand-700": string;
  "--chakra-colors-brand-800": string;
  "--chakra-colors-brand-900": string;
  "--chakra-borders-none": number;
  "--chakra-borders-1px": string;
  "--chakra-borders-2px": string;
  "--chakra-borders-4px": string;
  "--chakra-borders-8px": string;
  "--chakra-fonts-heading": string;
  "--chakra-fonts-body": string;
  "--chakra-fonts-mono": string;
  "--chakra-fontSizes-xs": string;
  "--chakra-fontSizes-sm": string;
  "--chakra-fontSizes-md": string;
  "--chakra-fontSizes-lg": string;
  "--chakra-fontSizes-xl": string;
  "--chakra-fontSizes-2xl": string;
  "--chakra-fontSizes-3xl": string;
  "--chakra-fontSizes-4xl": string;
  "--chakra-fontSizes-5xl": string;
  "--chakra-fontSizes-6xl": string;
  "--chakra-fontSizes-7xl": string;
  "--chakra-fontSizes-8xl": string;
  "--chakra-fontSizes-9xl": string;
  "--chakra-fontWeights-hairline": number;
  "--chakra-fontWeights-thin": number;
  "--chakra-fontWeights-light": number;
  "--chakra-fontWeights-normal": number;
  "--chakra-fontWeights-medium": number;
  "--chakra-fontWeights-semibold": number;
  "--chakra-fontWeights-bold": number;
  "--chakra-fontWeights-extrabold": number;
  "--chakra-fontWeights-black": number;
  "--chakra-letterSpacings-tighter": string;
  "--chakra-letterSpacings-tight": string;
  "--chakra-letterSpacings-normal": string;
  "--chakra-letterSpacings-wide": string;
  "--chakra-letterSpacings-wider": string;
  "--chakra-letterSpacings-widest": string;
  "--chakra-lineHeights-3": string;
  "--chakra-lineHeights-4": string;
  "--chakra-lineHeights-5": string;
  "--chakra-lineHeights-6": string;
  "--chakra-lineHeights-7": string;
  "--chakra-lineHeights-8": string;
  "--chakra-lineHeights-9": string;
  "--chakra-lineHeights-10": string;
  "--chakra-lineHeights-normal": string;
  "--chakra-lineHeights-none": number;
  "--chakra-lineHeights-shorter": number;
  "--chakra-lineHeights-short": number;
  "--chakra-lineHeights-base": number;
  "--chakra-lineHeights-tall": number;
  "--chakra-lineHeights-taller": string;
  "--chakra-radii-none": string;
  "--chakra-radii-sm": string;
  "--chakra-radii-base": string;
  "--chakra-radii-md": string;
  "--chakra-radii-lg": string;
  "--chakra-radii-xl": string;
  "--chakra-radii-2xl": string;
  "--chakra-radii-3xl": string;
  "--chakra-radii-full": string;
  "--chakra-space-1": string;
  "--chakra-space-2": string;
  "--chakra-space-3": string;
  "--chakra-space-4": string;
  "--chakra-space-5": string;
  "--chakra-space-6": string;
  "--chakra-space-7": string;
  "--chakra-space-8": string;
  "--chakra-space-9": string;
  "--chakra-space-10": string;
  "--chakra-space-12": string;
  "--chakra-space-14": string;
  "--chakra-space-16": string;
  "--chakra-space-20": string;
  "--chakra-space-24": string;
  "--chakra-space-28": string;
  "--chakra-space-32": string;
  "--chakra-space-36": string;
  "--chakra-space-40": string;
  "--chakra-space-44": string;
  "--chakra-space-48": string;
  "--chakra-space-52": string;
  "--chakra-space-56": string;
  "--chakra-space-60": string;
  "--chakra-space-64": string;
  "--chakra-space-72": string;
  "--chakra-space-80": string;
  "--chakra-space-96": string;
  "--chakra-space-px": string;
  "--chakra-space-0-5": string;
  "--chakra-space-1-5": string;
  "--chakra-space-2-5": string;
  "--chakra-space-3-5": string;
  "--chakra-shadows-xs": string;
  "--chakra-shadows-sm": string;
  "--chakra-shadows-base": string;
  "--chakra-shadows-md": string;
  "--chakra-shadows-lg": string;
  "--chakra-shadows-xl": string;
  "--chakra-shadows-2xl": string;
  "--chakra-shadows-outline": string;
  "--chakra-shadows-inner": string;
  "--chakra-shadows-none": string;
  "--chakra-shadows-dark-lg": string;
  "--chakra-sizes-1": string;
  "--chakra-sizes-2": string;
  "--chakra-sizes-3": string;
  "--chakra-sizes-4": string;
  "--chakra-sizes-5": string;
  "--chakra-sizes-6": string;
  "--chakra-sizes-7": string;
  "--chakra-sizes-8": string;
  "--chakra-sizes-9": string;
  "--chakra-sizes-10": string;
  "--chakra-sizes-12": string;
  "--chakra-sizes-14": string;
  "--chakra-sizes-16": string;
  "--chakra-sizes-20": string;
  "--chakra-sizes-24": string;
  "--chakra-sizes-28": string;
  "--chakra-sizes-32": string;
  "--chakra-sizes-36": string;
  "--chakra-sizes-40": string;
  "--chakra-sizes-44": string;
  "--chakra-sizes-48": string;
  "--chakra-sizes-52": string;
  "--chakra-sizes-56": string;
  "--chakra-sizes-60": string;
  "--chakra-sizes-64": string;
  "--chakra-sizes-72": string;
  "--chakra-sizes-80": string;
  "--chakra-sizes-96": string;
  "--chakra-sizes-px": string;
  "--chakra-sizes-0-5": string;
  "--chakra-sizes-1-5": string;
  "--chakra-sizes-2-5": string;
  "--chakra-sizes-3-5": string;
  "--chakra-sizes-max": string;
  "--chakra-sizes-min": string;
  "--chakra-sizes-full": string;
  "--chakra-sizes-3xs": string;
  "--chakra-sizes-2xs": string;
  "--chakra-sizes-xs": string;
  "--chakra-sizes-sm": string;
  "--chakra-sizes-md": string;
  "--chakra-sizes-lg": string;
  "--chakra-sizes-xl": string;
  "--chakra-sizes-2xl": string;
  "--chakra-sizes-3xl": string;
  "--chakra-sizes-4xl": string;
  "--chakra-sizes-5xl": string;
  "--chakra-sizes-6xl": string;
  "--chakra-sizes-7xl": string;
  "--chakra-sizes-8xl": string;
  "--chakra-sizes-container-sm": string;
  "--chakra-sizes-container-md": string;
  "--chakra-sizes-container-lg": string;
  "--chakra-sizes-container-xl": string;
  "--chakra-zIndices-hide": number;
  "--chakra-zIndices-auto": string;
  "--chakra-zIndices-base": number;
  "--chakra-zIndices-docked": number;
  "--chakra-zIndices-dropdown": number;
  "--chakra-zIndices-sticky": number;
  "--chakra-zIndices-banner": number;
  "--chakra-zIndices-overlay": number;
  "--chakra-zIndices-modal": number;
  "--chakra-zIndices-popover": number;
  "--chakra-zIndices-skipLink": number;
  "--chakra-zIndices-toast": number;
  "--chakra-zIndices-tooltip": number;
  "--chakra-transition-property-common": string;
  "--chakra-transition-property-colors": string;
  "--chakra-transition-property-dimensions": string;
  "--chakra-transition-property-position": string;
  "--chakra-transition-property-background": string;
  "--chakra-transition-easing-ease-in": string;
  "--chakra-transition-easing-ease-out": string;
  "--chakra-transition-easing-ease-in-out": string;
  "--chakra-transition-duration-ultra-fast": string;
  "--chakra-transition-duration-faster": string;
  "--chakra-transition-duration-fast": string;
  "--chakra-transition-duration-normal": string;
  "--chakra-transition-duration-slow": string;
  "--chakra-transition-duration-slower": string;
  "--chakra-transition-duration-ultra-slow": string;
  "--chakra-blur-none": number;
  "--chakra-blur-sm": string;
  "--chakra-blur-base": string;
  "--chakra-blur-md": string;
  "--chakra-blur-lg": string;
  "--chakra-blur-xl": string;
  "--chakra-blur-2xl": string;
  "--chakra-blur-3xl": string;
}

export interface Borders {
  none: number;
  "1px": string;
  "2px": string;
  "4px": string;
  "8px": string;
}

export interface Colors {
  transparent: string;
  current: string;
  black: string;
  white: string;
  whiteAlpha: { [key: string]: string };
  blackAlpha: { [key: string]: string };
  gray: { [key: string]: string };
  red: { [key: string]: string };
  orange: { [key: string]: string };
  yellow: { [key: string]: string };
  green: { [key: string]: string };
  teal: { [key: string]: string };
  blue: { [key: string]: string };
  cyan: { [key: string]: string };
  purple: { [key: string]: string };
  pink: { [key: string]: string };
  linkedin: { [key: string]: string };
  facebook: { [key: string]: string };
  messenger: { [key: string]: string };
  whatsapp: { [key: string]: string };
  twitter: { [key: string]: string };
  telegram: { [key: string]: string };
  // custom
  brand: { [key: string]: string };
  // from tailwind
  slate: { [key: string]: string };
  zinc: { [key: string]: string };
  neutral: { [key: string]: string };
  stone: { [key: string]: string };
  amber: { [key: string]: string };
  lime: { [key: string]: string };
  emerald: { [key: string]: string };
  sky: { [key: string]: string };
  indigo: { [key: string]: string };
  violet: { [key: string]: string };
  fuchsia: { [key: string]: string };
  rose: { [key: string]: string };
}

export interface Components {
  Accordion: Accordion;
  Alert: Alert;
  Avatar: Avatar;
  Badge: Badge;
  Breadcrumb: Breadcrumb;
  Button: ButtonClass;
  Checkbox: Checkbox;
  CloseButton: CloseButton;
  Code: Code;
  Container: Container;
  Divider: Divider;
  Drawer: Drawer;
  Editable: Editable;
  Form: Form;
  FormError: Form;
  FormLabel: FormLabel;
  Heading: Heading;
  Input: InputClass;
  Kbd: Kbd;
  Link: Link;
  List: List;
  Menu: Form;
  Modal: Modal;
  NumberInput: NumberInput;
  PinInput: PinInput;
  Popover: Form;
  Progress: Progress;
  Radio: Radio;
  Select: Select;
  Skeleton: Kbd;
  SkipLink: Kbd;
  Slider: Slider;
  Spinner: Spinner;
  Stat: Stat;
  Switch: Switch;
  Table: Table;
  Tabs: Tabs;
  Tag: Tag;
  Textarea: Textarea;
  Tooltip: Kbd;
}

export interface Accordion {
  parts: string[];
  baseStyle: AccordionBaseStyle;
  defaultProps: PurpleDefaultProps;
}

export interface AccordionBaseStyle {
  root: Styles;
  container: PurpleContainer;
  button: Button;
  panel: Panel;
  icon: LabelClass;
}

export interface Button {
  transitionProperty: string;
  transitionDuration: string;
  fontSize: string;
  _focus: ButtonFocus;
  _hover: Hover;
  _disabled: ButtonDisabled;
  px: number;
  py: number;
}

export interface ButtonDisabled {
  opacity: number;
  cursor: string;
}

export interface ButtonFocus {
  boxShadow: string;
}

export interface Hover {
  bg: string;
}

export interface PurpleContainer {
  borderTopWidth: string;
  borderColor: string;
  _last: ContainerLast;
}

export interface ContainerLast {
  borderBottomWidth: string;
}

export interface LabelClass {
  fontSize: string;
}

export interface Panel {
  pt: number;
  px: number;
  pb: number;
}

export interface PurpleDefaultProps {
  colorScheme: ColorScheme;
}

export enum ColorScheme {
  Brand = "brand",
  Slate = "slate",
  Zinc = "zinc",
  Neutral = "neutral",
  Stone = "stone",
  Amber = "amber",
  Lime = "lime",
  Emerald = "emerald",
  Sky = "sky",
  Indigo = "indigo",
  Violet = "violet",
  Fuchsia = "fuchsia",
  Rose = "rose",
}

export interface Alert {
  parts: string[];
  baseStyle: AlertBaseStyle;
  variants: Styles;
  defaultProps: AlertDefaultProps;
}

export interface AlertBaseStyle {
  container: FluffyContainer;
  title: Title;
  description: Description;
  icon: PurpleIcon;
}

export interface FluffyContainer {
  px: number;
  py: number;
}

export interface Description {
  lineHeight: number;
}

export interface PurpleIcon {
  flexShrink: number;
  marginEnd: number;
  w: number;
  h: number;
}

export interface Title {
  fontWeight: string;
  lineHeight: number;
  marginEnd: number;
}

export interface AlertDefaultProps {
  variant: string;
  colorScheme: ColorScheme;
}

export interface Avatar {
  parts: string[];
  sizes: AvatarSizes;
  defaultProps: FluffyDefaultProps;
}

export interface FluffyDefaultProps {
  size: Size;
  colorScheme: ColorScheme;
}

export enum Size {
  Md = "md",
  Xl = "xl",
  Xs = "xs",
}

export interface AvatarSizes {
  "2xs": Purple2_Xl;
  xs: Purple2_Xl;
  sm: Purple2_Xl;
  md: Purple2_Xl;
  lg: Purple2_Xl;
  xl: Purple2_Xl;
  "2xl": Purple2_Xl;
  full: PurpleFull;
}

export interface Purple2_Xl {
  container: The2XlContainer;
  excessLabel: ExcessLabel;
  label: The2XlLabel;
}

export interface The2XlContainer {
  width: string;
  height: string;
  fontSize: string;
}

export interface ExcessLabel {
  width: string;
  height: string;
}

export interface The2XlLabel {
  fontSize: string;
  lineHeight: string;
}

export interface PurpleFull {
  container: The2XlContainer;
  excessLabel: ExcessLabel;
  label: LabelClass;
}

export interface Badge {
  baseStyle: BadgeBaseStyle;
  variants: Styles;
  defaultProps: AlertDefaultProps;
}

export interface BadgeBaseStyle {
  px: number;
  textTransform: string;
  fontSize: Size;
  borderRadius: BorderRadius;
  fontWeight: string;
}

export enum BorderRadius {
  Lg = "lg",
  Md = "md",
  Sm = "sm",
  Xs = "xs",
}

export interface Breadcrumb {
  parts: string[];
  baseStyle: BreadcrumbBaseStyle;
  defaultProps: PurpleDefaultProps;
}

export interface BreadcrumbBaseStyle {
  link: LinkClass;
}

export interface LinkClass {
  transitionProperty: string;
  transitionDuration: string;
  transitionTimingFunction: string;
  cursor: string;
  textDecoration: string;
  outline: string;
  color: string;
  _hover: LinkHover;
  _focus: ButtonFocus;
}

export interface LinkHover {
  textDecoration: string;
}

export interface ButtonClass {
  baseStyle: ButtonBaseStyle;
  variants: ButtonVariants;
  sizes: ButtonSizes;
  defaultProps: ButtonDefaultProps;
}

export interface ButtonBaseStyle {
  lineHeight: string;
  borderRadius: Size;
  fontWeight: string;
  transitionProperty: string;
  transitionDuration: string;
  _focus: ButtonFocus;
  _disabled: BaseStyleDisabled;
  _hover: PurpleHover;
}

export interface BaseStyleDisabled {
  opacity: number;
  cursor: string;
  boxShadow: string;
}

export interface PurpleHover {
  _disabled: Hover;
}

export interface ButtonDefaultProps {
  variant: string;
  size: Size;
  colorScheme: ColorScheme;
}

export interface ButtonSizes {
  lg: AddonClass;
  md: AddonClass;
  sm: AddonClass;
  xs: AddonClass;
}

export interface AddonClass {
  h: number;
  minW?: number;
  fontSize: BorderRadius;
  px?: number;
  borderRadius?: BorderRadius;
  w?: number;
  paddingInlineEnd?: string;
}

export interface ButtonVariants {
  unstyled: PurpleUnstyled;
}

export interface PurpleUnstyled {
  bg: string;
  color: string;
  display: string;
  lineHeight: string;
  m: number;
  p: number;
}

export interface Checkbox {
  parts: string[];
  sizes: CheckboxSizes;
  defaultProps: FluffyDefaultProps;
}

export interface CheckboxSizes {
  sm: PurpleLg;
  md: PurpleLg;
  lg: PurpleLg;
}

export interface PurpleLg {
  control: LgControl;
  label: LabelClass;
  icon: LabelClass;
}

export interface LgControl {
  h: number;
  w: number;
}

export interface CloseButton {
  sizes: CloseButtonSizes;
  defaultProps: FluffyDefaultProps;
}

export interface CloseButtonSizes {
  lg: FluffyLg;
  md: FluffyLg;
  sm: FluffyLg;
}

export interface FluffyLg {
  "--close-button-size": string;
  fontSize: string;
}

export interface Code {
  baseStyle: CodeBaseStyle;
  variants: Styles;
  defaultProps: AlertDefaultProps;
}

export interface CodeBaseStyle {
  fontFamily: string;
  fontSize: BorderRadius;
  px: string;
  borderRadius: BorderRadius;
}

export interface Container {
  baseStyle: ContainerBaseStyle;
  defaultProps: PurpleDefaultProps;
}

export interface ContainerBaseStyle {
  w: string;
  mx: string;
  maxW: string;
  px: string;
}

export interface Divider {
  baseStyle: DividerBaseStyle;
  variants: DividerVariants;
  defaultProps: AlertDefaultProps;
}

export interface DividerBaseStyle {
  opacity: number;
  borderColor: string;
}

export interface DividerVariants {
  solid: Dashed;
  dashed: Dashed;
}

export interface Dashed {
  borderStyle: string;
}

export interface Drawer {
  parts: string[];
  sizes: DrawerSizes;
  defaultProps: FluffyDefaultProps;
}

export interface DrawerSizes {
  xs: The3XlClass;
  sm: The3XlClass;
  md: The3XlClass;
  lg: The3XlClass;
  xl: The3XlClass;
  full: FluffyFull;
}

export interface FluffyFull {
  dialog: PurpleDialog;
}

export interface PurpleDialog {
  maxW: string;
  h: string;
}

export interface The3XlClass {
  dialog: FluffyDialog;
}

export interface FluffyDialog {
  maxW: string;
}

export interface Editable {
  parts: string[];
  baseStyle: EditableBaseStyle;
  defaultProps: PurpleDefaultProps;
}

export interface EditableBaseStyle {
  preview: Preview;
  input: Input;
  textarea: Input;
}

export interface Input {
  borderRadius: Size;
  py: string;
  transitionProperty: string;
  transitionDuration: string;
  width: string;
  _focus: ButtonFocus;
  _placeholder: Disabled;
}

export interface Disabled {
  opacity: number;
}

export interface Preview {
  borderRadius: Size;
  py: string;
  transitionProperty: string;
  transitionDuration: string;
}

export interface Form {
  parts: string[];
  defaultProps: PurpleDefaultProps;
}

export interface FormLabel {
  baseStyle: FormLabelBaseStyle;
  defaultProps: PurpleDefaultProps;
}

export interface FormLabelBaseStyle {
  fontSize: Size;
  marginEnd: number;
  mb: number;
  fontWeight: string;
  transitionProperty: string;
  transitionDuration: string;
  opacity: number;
  _disabled: Disabled;
}

export interface Heading {
  baseStyle: HeadingBaseStyle;
  sizes: HeadingSizes;
  defaultProps: FluffyDefaultProps;
}

export interface HeadingBaseStyle {
  fontFamily: string;
  fontWeight: string;
}

export interface HeadingSizes {
  "4xl": Xl;
  "3xl": Xl;
  "2xl": The2XlClass;
  xl: The2XlClass;
  lg: The2XlClass;
  md: SmClass;
  sm: SmClass;
  xs: SmClass;
}

export interface The2XlClass {
  fontSize: Array<null | string>;
  lineHeight: Array<number | null>;
}

export interface Xl {
  fontSize: Array<null | string>;
  lineHeight: number;
}

export interface SmClass {
  fontSize: string;
  lineHeight: number;
}

export interface InputClass {
  parts: string[];
  baseStyle: InputBaseStyle;
  sizes: InputSizes;
  variants: InputVariants;
  defaultProps: ButtonDefaultProps;
}

export interface InputBaseStyle {
  field: FieldClass;
}

export interface FieldClass {
  width: string;
  minWidth: number;
  outline: number;
  position: string;
  appearance: string;
  transitionProperty: string;
  transitionDuration: string;
  textAlign?: string;
}

export interface InputSizes {
  lg: TentacledLg;
  md: TentacledLg;
  sm: TentacledLg;
  xs: TentacledLg;
}

export interface TentacledLg {
  field: AddonClass;
  addon: AddonClass;
}

export interface InputVariants {
  unstyled: FluffyUnstyled;
}

export interface FluffyUnstyled {
  field: UnstyledUnstyled;
  addon: UnstyledUnstyled;
}

export interface UnstyledUnstyled {
  bg: string;
  px: number;
  height: string;
}

export interface Kbd {
  defaultProps: PurpleDefaultProps;
}

export interface Link {
  baseStyle: LinkClass;
  defaultProps: PurpleDefaultProps;
}

export interface List {
  parts: string[];
  baseStyle: ListBaseStyle;
  defaultProps: PurpleDefaultProps;
}

export interface ListBaseStyle {
  container: Styles;
  item: Styles;
  icon: FluffyIcon;
}

export interface FluffyIcon {
  marginEnd: string;
  display: string;
  verticalAlign: string;
}

export interface Modal {
  parts: string[];
  sizes: ModalSizes;
  defaultProps: FluffyDefaultProps;
}

export interface ModalSizes {
  xs: The3XlClass;
  sm: The3XlClass;
  md: The3XlClass;
  lg: The3XlClass;
  xl: The3XlClass;
  "2xl": The3XlClass;
  "3xl": The3XlClass;
  "4xl": The3XlClass;
  "5xl": The3XlClass;
  "6xl": The3XlClass;
  full: TentacledFull;
}

export interface TentacledFull {
  dialog: TentacledDialog;
}

export interface TentacledDialog {
  maxW: string;
  minH: string;
  "@supports(min-height: -webkit-fill-available)": SupportsMinHeightWebkitFillAvailable;
  my: number;
}

export interface SupportsMinHeightWebkitFillAvailable {
  minH: string;
}

export interface NumberInput {
  parts: string[];
  sizes: NumberInputSizes;
  variants: InputVariants;
  defaultProps: ButtonDefaultProps;
}

export interface NumberInputSizes {
  xs: StickyLg;
  sm: StickyLg;
  md: StickyLg;
  lg: StickyLg;
}

export interface StickyLg {
  field: Field;
  stepper: Stepper;
}

export interface Field {
  fontSize: BorderRadius;
  px: number;
  h: number;
  borderRadius: BorderRadius;
  paddingInlineEnd: string;
  verticalAlign: string;
}

export interface Stepper {
  fontSize: string;
  _first: First;
  _last: StepperLast;
}

export interface First {
  borderTopEndRadius: BorderRadius;
}

export interface StepperLast {
  borderBottomEndRadius: BorderRadius;
  mt: string;
  borderTopWidth: number;
}

export interface PinInput {
  baseStyle: FieldClass;
  sizes: ButtonSizes;
  variants: PinInputVariants;
  defaultProps: ButtonDefaultProps;
}

export interface PinInputVariants {
  unstyled: UnstyledUnstyled;
}

export interface Progress {
  parts: string[];
  sizes: ProgressSizes;
  defaultProps: FluffyDefaultProps;
}

export interface ProgressSizes {
  xs: IndigoLg;
  sm: IndigoLg;
  md: IndigoLg;
  lg: IndigoLg;
}

export interface IndigoLg {
  track: Track;
}

export interface Track {
  h: string;
}

export interface Radio {
  parts: string[];
  sizes: RadioSizes;
  defaultProps: FluffyDefaultProps;
}

export interface RadioSizes {
  md: IndecentLg;
  lg: IndecentLg;
  sm: PurpleSm;
}

export interface IndecentLg {
  control: LgControl;
  label: LabelClass;
}

export interface PurpleSm {
  control: PurpleControl;
  label: LabelClass;
}

export interface PurpleControl {
  width: number;
  height: number;
}

export interface Select {
  parts: string[];
  sizes: SelectSizes;
  variants: InputVariants;
  defaultProps: ButtonDefaultProps;
}

export interface SelectSizes {
  lg: TentacledLg;
  md: TentacledLg;
  sm: TentacledLg;
  xs: Xs;
}

export interface Xs {
  field: AddonClass;
  addon: AddonClass;
  icon: XsIcon;
}

export interface XsIcon {
  insetEnd: string;
}

export interface Slider {
  parts: string[];
  sizes: Styles;
  defaultProps: FluffyDefaultProps;
}

export interface Spinner {
  baseStyle: SpinnerBaseStyle;
  sizes: SpinnerSizes;
  defaultProps: FluffyDefaultProps;
}

export interface SpinnerBaseStyle {
  width: string[];
  height: string[];
}

export interface SpinnerSizes {
  xs: SizesXl;
  sm: SizesXl;
  md: SizesXl;
  lg: SizesXl;
  xl: SizesXl;
}

export interface SizesXl {
  "--spinner-size": string;
}

export interface Stat {
  parts: string[];
  baseStyle: StatBaseStyle;
  sizes: StatSizes;
  defaultProps: FluffyDefaultProps;
}

export interface StatBaseStyle {
  container: Styles;
  label: PurpleLabel;
  helpText: HelpText;
  number: number;
  icon: TentacledIcon;
}

export interface HelpText {
  opacity: number;
  marginBottom: number;
}

export interface TentacledIcon {
  marginEnd: number;
  w: string;
  h: string;
  verticalAlign: string;
}

export interface PurpleLabel {
  fontWeight: string;
}

export interface Number {
  verticalAlign: string;
  fontWeight: string;
}

export interface StatSizes {
  md: PurpleMd;
}

export interface PurpleMd {
  label: LabelClass;
  helpText: LabelClass;
  number: LabelClass;
}

export interface Switch {
  parts: string[];
  sizes: SwitchSizes;
  defaultProps: FluffyDefaultProps;
}

export interface SwitchSizes {
  sm: HilariousLg;
  md: HilariousLg;
  lg: HilariousLg;
}

export interface HilariousLg {
  container: TentacledContainer;
}

export interface TentacledContainer {
  "--switch-track-width": string;
  "--switch-track-height": string;
}

export interface Table {
  parts: string[];
  baseStyle: TableBaseStyle;
  variants: TableVariants;
  sizes: TableSizes;
  defaultProps: ButtonDefaultProps;
}

export interface TableBaseStyle {
  table: TableClass;
  th: Th;
  td: TdClass;
  caption: BaseStyleCaption;
}

export interface BaseStyleCaption {
  mt: number;
  fontFamily: string;
  textAlign: string;
  fontWeight: string;
}

export interface TableClass {
  fontVariantNumeric: string;
  borderCollapse: string;
  width: string;
}

export interface TdClass {
  textAlign: string;
}

export interface Th {
  fontFamily: string;
  fontWeight: string;
  textTransform: string;
  letterSpacing: string;
  textAlign: string;
}

export interface TableSizes {
  sm: FluffySm;
  md: AmbitiousLg;
  lg: AmbitiousLg;
}

export interface AmbitiousLg {
  th: PurpleTd;
  td: PurpleTd;
  caption: LgCaption;
}

export interface LgCaption {
  px: string;
  py: string;
  fontSize: BorderRadius;
}

export interface PurpleTd {
  px: string;
  py: string;
  lineHeight?: string;
  fontSize?: BorderRadius;
}

export interface FluffySm {
  th: PurpleTd;
  td: PurpleTd;
  caption: PurpleTd;
}

export interface TableVariants {
  unstyled: Styles;
}

export interface Tabs {
  parts: string[];
  sizes: TabsSizes;
  variants: TableVariants;
  defaultProps: ButtonDefaultProps;
}

export interface TabsSizes {
  sm: CunningLg;
  md: CunningLg;
  lg: CunningLg;
}

export interface CunningLg {
  tab: Tab;
}

export interface Tab {
  fontSize: BorderRadius;
  py: number;
  px: number;
}

export interface Tag {
  parts: string[];
  variants: Styles;
  baseStyle: TagBaseStyle;
  sizes: TagSizes;
  defaultProps: ButtonDefaultProps;
}

export interface TagBaseStyle {
  container: StickyContainer;
  label: FluffyLabel;
  closeButton: BaseStyleCloseButton;
}

export interface BaseStyleCloseButton {
  fontSize: string;
  w: string;
  h: string;
  transitionProperty: string;
  transitionDuration: string;
  borderRadius: string;
  marginStart: string;
  marginEnd: string;
  opacity: number;
  _disabled: Disabled;
  _focus: CloseButtonFocus;
  _hover: Disabled;
  _active: Disabled;
}

export interface CloseButtonFocus {
  boxShadow: string;
  bg: string;
}

export interface StickyContainer {
  fontWeight: string;
  lineHeight: number;
  outline: number;
  _focus: ButtonFocus;
}

export interface FluffyLabel {
  lineHeight: number;
  overflow: string;
}

export interface TagSizes {
  sm: TentacledSm;
  md: FluffyMd;
  lg: MagentaLg;
}

export interface MagentaLg {
  container: IndigoContainer;
}

export interface IndigoContainer {
  minH: number;
  minW: number;
  fontSize: Size;
  borderRadius: Size;
  px: number;
}

export interface FluffyMd {
  container: IndecentContainer;
}

export interface IndecentContainer {
  minH: string;
  minW: string;
  fontSize: BorderRadius;
  borderRadius: Size;
  px: number;
}

export interface TentacledSm {
  container: IndecentContainer;
  closeButton: SmCloseButton;
}

export interface SmCloseButton {
  marginEnd: string;
  marginStart: string;
}

export interface Textarea {
  baseStyle: TextareaBaseStyle;
  sizes: ButtonSizes;
  variants: PinInputVariants;
  defaultProps: ButtonDefaultProps;
}

export interface TextareaBaseStyle {
  width: string;
  minWidth: number;
  outline: number;
  position: string;
  appearance: string;
  transitionProperty: string;
  transitionDuration: string;
  paddingY: string;
  minHeight: string;
  lineHeight: string;
  verticalAlign: string;
}

export interface Config {
  useSystemColorMode: boolean;
  initialColorMode: string;
  cssVarPrefix: string;
}

export interface FontWeights {
  hairline: number;
  thin: number;
  light: number;
  normal: number;
  medium: number;
  semibold: number;
  bold: number;
  extrabold: number;
  black: number;
}

export interface Fonts {
  heading: string;
  body: string;
  mono: string;
}

export interface LetterSpacings {
  tighter: string;
  tight: string;
  normal: string;
  wide: string;
  wider: string;
  widest: string;
}

export interface LineHeights {
  "3": string;
  "4": string;
  "5": string;
  "6": string;
  "7": string;
  "8": string;
  "9": string;
  "10": string;
  normal: string;
  none: number;
  shorter: number;
  short: number;
  base: number;
  tall: number;
  taller: string;
}

export interface Radii {
  none: string;
  sm: string;
  base: string;
  md: string;
  lg: string;
  xl: string;
  "2xl": string;
  "3xl": string;
  full: string;
}

export interface Shadows {
  xs: string;
  sm: string;
  base: string;
  md: string;
  lg: string;
  xl: string;
  "2xl": string;
  outline: string;
  inner: string;
  none: string;
  "dark-lg": string;
}

export interface TopLevelSizes {
  "1": string;
  "2": string;
  "3": string;
  "4": string;
  "5": string;
  "6": string;
  "7": string;
  "8": string;
  "9": string;
  "10": string;
  "12": string;
  "14": string;
  "16": string;
  "20": string;
  "24": string;
  "28": string;
  "32": string;
  "36": string;
  "40": string;
  "44": string;
  "48": string;
  "52": string;
  "56": string;
  "60": string;
  "64": string;
  "72": string;
  "80": string;
  "96": string;
  px: string;
  "0.5": string;
  "1.5": string;
  "2.5": string;
  "3.5": string;
  max: string;
  min: string;
  full: string;
  "3xs": string;
  "2xs": string;
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  "2xl": string;
  "3xl": string;
  "4xl": string;
  "5xl": string;
  "6xl": string;
  "7xl": string;
  "8xl": string;
  container: SizesContainer;
}

export interface SizesContainer {
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

export interface Transition {
  property: Property;
  easing: Easing;
  duration: Duration;
}

export interface Duration {
  "ultra-fast": string;
  faster: string;
  fast: string;
  normal: string;
  slow: string;
  slower: string;
  "ultra-slow": string;
}

export interface Easing {
  "ease-in": string;
  "ease-out": string;
  "ease-in-out": string;
}

export interface Property {
  common: string;
  colors: string;
  dimensions: string;
  position: string;
  background: string;
}

export interface ZIndices {
  hide: number;
  auto: string;
  base: number;
  docked: number;
  dropdown: number;
  sticky: number;
  banner: number;
  overlay: number;
  modal: number;
  popover: number;
  skipLink: number;
  toast: number;
  tooltip: number;
}
